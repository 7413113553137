import React from 'react';
import { getAttributes } from '../utils';
import { useHandleAction } from './handle-action';


export const Button = ({ item, totalColumns }) => {

  const componentAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: 'component'
  });

  const handleAction = useHandleAction(item);

  return (
    <button {...componentAttributes} onClick={handleAction}>
      {item.content}
    </button>
  );
}