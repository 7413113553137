import React, { useState } from "react";
import { Plus } from "phosphor-react";
import { css } from "emotion";
import { flex } from "../../../shared-components/shared-styles";
import { Collapse } from "@material-ui/core";

export const Accordion = ({ item, totalColumns }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={accordionStyle(expanded)}
      onClick={() => setExpanded(!expanded)}
    >
      <div className="title">
        <div className="svg-container">
          <Plus />
        </div>
        <pre
          className="text-h4-component"
          dangerouslySetInnerHTML={{ __html: item?.data?.title?.content || "" }}
        ></pre>
      </div>
      <Collapse in={expanded}>
        <div
          className={css`
            margin-left: 40px;
            margin-top: 8px;
          `}
        >
          <pre
            className="text-body-component"
            dangerouslySetInnerHTML={{
              __html: item?.data?.body?.content || "",
            }}
          ></pre>
        </div>
      </Collapse>
    </div>
  );
};

const rotateForward = `
  @keyframes rotateFoward {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(45deg);
    }
  }
`;

const rotateBackward = `
  @keyframes rotateBackward {
    from {
      transform: rotate(45deg);
    }
    to {
      transform: rotate(0);
    }
  }
`;

const accordionStyle = (expanded) => css`
  padding: 16px;
  margin: 8px;
  width: calc(100% - 48px);
  border-radius: 8px;
  border: 1px solid var(--accent-color-4);
  cursor: pointer;
  :hover {
    outline: 2px solid var(--accent-color-0);
    outline-offset: -2px;
  }
  .title {
    ${flex("left")}
    .svg-container {
      ${flex("center")}
      border-radius: 50%;
      background-color: var(--text-body);
      color: var(--background-color);
      margin-right: 16px;
      min-width: 24px;
      min-height: 24px;
      ${rotateBackward}
      ${rotateForward}
      svg {
        width: 16px;
        height: 16px;
        ${expanded
          ? `animation: rotateFoward .4s forwards;`
          : `animation: rotateBackward .4s forwards;`}
      }
    }
  }

  .text-body-component {
    margin-left: 40px;
  }

  .content {
    margin-left: 40px;
    margin-top: 8px;
  }

  pre {
    ol,
    ul,
    p {
      margin: 0;
      li {
        margin: 0;
      }
    }
  }
`;
