import { css, cx } from "emotion";
import { WarningCircle, X } from "phosphor-react";
import { useCallback, useMemo, useState } from "react";
import { CarrierLogo } from "../../components/brite-viewer/components/benefits/carrier-logo";
import {
  largeScreen,
  smallScreen,
} from "../../components/decision-tool-views/additional-benefits/product-components/shared-styles";
import { Button } from "../button";
import { Div } from "../div";
import { container, flex } from "../shared-styles";
import { colors } from "../styles";
import { Text } from "../text";
import parse from "html-react-parser";
import { SummaryField } from "../../components/brite-viewer/components/benefits/summary-field";
import { RecommendationFieldList } from "./recommendation-field-list";
import { useRecommendations } from "./use-rec-config";
import {
  getDisplayValue,
  getFieldValue,
  getProductPremium,
} from "../../components/brite-viewer/components/benefits/field-utils";
import { posthogAttrs } from "../../posthog-constants";
import { Modal } from "../modal";

export const ProductRecommendation = ({
  recommendation,
  products,
  productLayouts,
  selected = [],
  setSelected,
  displaySettings,

  isMulti,
  productTypeIds = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { product, layout, config } = useRecommendations({
    productId: recommendation?.Product?.ID,
    productLayouts,
    products,
  });

  const coreData = useRecommendations({
    productId: recommendation?.Product?.ID,
    products,
    productLayouts,
  });

  const handleSelect = (e) => {
    e.stopPropagation();
    let list = selected.filter((item) => !productTypeIds.includes(item));

    if (!selected.includes(product?.ID)) {
      list.push(product?.ID);
    }

    setSelected(list);
  };

  const isSelected = selected?.includes(product?.ID);

  const Logo = useCallback(
    ({ className }) => {
      return product?.ProviderID &&
        product?.ProviderID !== "00000000-0000-0000-0000-000000000000" ? (
        <Div
          className={cx(
            className,
            css`
              border: 1px solid ${colors.gray[300]};
              border-radius: 8px;
              max-width: 164px;
              min-width: 164px;
              background-color: white;
              ${flex("center")}
            `
          )}
        >
          <CarrierLogo
            carrierID={product?.ProviderID}
            planCarrierName={product?.ProviderName}
            maxHeight="80px"
            position="center"
          />
        </Div>
      ) : null;
    },
    [product?.ProviderID]
  );

  const CardAlert =
    product?.Type in CardAlerts ? CardAlerts[product?.Type] : null;

  const disclaimer = `*There are other aspects of this benefit to consider, including pricing, coverage limitations, exclusions, reductions and other ways this benefit will or will not pay. Please refer to your plan documents for full coverage.`;

  const parseDisclaimer = (text) => {
    var exp = /(?:^|[^"'])((ftp|http|https|file):\/\/[\S]+(\b|$))/gi;
    const disclaimer =
      text?.replace(exp, " <a href='$1' target='_blank'>$1</a>") || "";
    return parse(disclaimer);
  };

  const titleDescriptions = product?.TitleDescriptions?.filter(
    ({ Autogenerated }) => !Autogenerated
  );

  const costLayout = useMemo(() => {
    const layoutObject = layout?.Layout?.Sections?.reduce((prev, item) => {
      return item?.Fields?.reduce(
        (p, field) => ({
          ...p,
          [field?.PropertyChain]: field,
        }),
        prev
      );
    }, {});
    return layoutObject?.Cost;
  }, [layout]);

  return (
    <Div
      css={css`
        width: 100%;
        cursor: default;
        padding: 32px;
        background-color: white;
        border-radius: 16px;
        box-sizing: border-box;
        margin: 16px 0;
        border: 1px solid ${colors.gray[300]};
        .logo {
          width: 150px;
        }
        .small-screen {
          padding: 24px;
        }
        ${isMulti
          ? `
          border: 1px solid ${colors.gray[300]};
          padding: 24px;
          margin: 32px 0;
          width: 100%;
        `
          : ``}
        ${isSelected
          ? `
          border: 1px solid transparent;
          outline: 4px solid var(--button-background-color);
        `
          : `
            ${container.hover}
          `}
      `}
      onClick={handleSelect}
    >
      <Div
        css={css`
          ${flex("space-between start")}
          margin-bottom: 8px;
          width: 100%;
          .large-screen {
            height: 80px;
            margin-left: 40px;
            ${flex("center")}
          }
          .small-screen {
            margin: 16px 0;
          }
          ${smallScreen} {
            .large-screen {
              display: none;
            }
          }
          ${largeScreen} {
            .small-screen {
              display: none;
            }
          }
        `}
      >
        <Div
          css={css`
            width: 100%;
            flex-grow: 1;
          `}
        >
          <Div
            css={css`
              .title {
                ${flex("space-between start")}
                width: 100%;
                flex-grow: 1;
                margin: 0;
                padding: 0;
              }
              ${largeScreen} {
                ${flex("space-between start")}
              }
              ${smallScreen} {
                ${flex("column center")}
              }
              width: 100%;
              padding: 0;
            `}
          >
            <div className="title">
              <Text
                h2
                css={`
                  flex-grow: 1;
                  text-align: left;
                  font-size: 20px;
                  padding-bottom: 8px;
                `}
              >
                {product?.ProductName || coreData?.layout?.Title}
              </Text>
              {!costLayout || costLayout?.State === "show" ? (
                <Text
                  h2
                  css={`
                    font-size: 20px;
                    padding-bottom: 8px;
                    min-width: max-content;
                  `}
                >
                  {recommendation?.RelevantTier
                    ? `${getProductPremium({
                        cost: product?.Cost,
                        key: recommendation?.RelevantTier,
                        interval: displaySettings?.premiumInterval,
                      })} ${
                        displaySettings?.premiumInterval === 12 ? "/mo" : "/ppp"
                      }`
                    : null}
                </Text>
              ) : null}
            </div>

            <Logo className="small-screen" />
          </Div>

          <Text
            css={`
              padding-bottom: 8px;
            `}
          >
            {coreData?.layout?.Subtitle}
          </Text>
          <Button
            css={`
              border: none;
              background: none;
              text-transform: none;
              text-decoration: underline;
              color: ${colors.black};
              padding: 0;
              font-size: 14px;
              line-height: 24px;
            `}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
            data-ph-capture-attribute-source={
              posthogAttrs?.types?.RECOMMENDATIONS
            }
            data-ph-capture-attribute-action={posthogAttrs?.actions?.VIEW_MORE}
            data-ph-capture-attribute-product-type={product?.Type}
            data-ph-capture-attribute-product-id={product?.ID}
          >
            Learn More
          </Button>
        </Div>
        <Logo className={cx("large-screen", "logo")} />
      </Div>

      {CardAlert ? (
        <CardAlert product={coreData?.product} data={coreData?.config?.data} />
      ) : null}

      <Modal display={isOpen} onClose={() => setIsOpen(false)}>
        <Div
          css={css`
            padding: 24px;
            width: 500px;
            ${smallScreen} {
              max-width: 100vw;
              padding: 0;
            }
          `}
        >
          <Div
            css={css`
              ${flex("space-between")}
            `}
          >
            <Text h2>Plan Details</Text>
            <Button styles="icon" onClick={() => setIsOpen(false)}>
              <X />
            </Button>
          </Div>
          <Div
            css={css`
              border-top: 1px solid ${colors.gray[200]};
              padding: 16px 0;
              padding-top: 0;
              margin-top: 16px;
              border-bottom: 1px solid ${colors.gray[200]};
              max-height: 50vh;
              ${smallScreen} {
                max-height: 100vh;
              }
              overflow-y: auto;
            `}
            styles="alternatingList"
            onClick={() => console.log(layout)}
          >
            {product?.ProductName && product?.Type !== "supplimental_life" ? (
              <Div
                css={css`
                  background-color: ${colors.gray[100]};
                  padding: 16px;
                  margin-bottom: 16px;
                  ${flex("space-between")}
                `}
              >
                <Text label>Plan Name</Text>
                <Text label bold>
                  {product?.ProductName}
                </Text>
              </Div>
            ) : (
              <Div
                css={css`
                  margin: 16px 0;
                `}
              />
            )}

            <Text
              label
              css={`
                margin-bottom: 16px;
                padding: 0 8px;
              `}
            >
              {layout?.Description}
            </Text>

            {detailTitlesByType?.[product?.Type] ? (
              <Text
                label
                bold
                css={`
                  margin-bottom: 16px;
                `}
              >
                {detailTitlesByType?.[product?.Type]}
              </Text>
            ) : null}

            <RecommendationFieldList
              product={product}
              fields={config?.fields}
            />

            {product?.Details?.ConditionPercentages?.length > 0 && (
              <>
                <Text
                  label
                  bold
                  css={css`
                    padding: 16px 32px;
                  `}
                >
                  Listed Conditions
                </Text>
                {product?.Details?.ConditionPercentages?.map(
                  ({ Name }, idx) => (
                    <SummaryField
                      product={product}
                      field={{
                        DisplayValue: Name,
                        State: "show",
                        Type: "percent",
                        PropertyChain: `Details.ConditionPercentages[${idx}].Percentage`,
                      }}
                    />
                  )
                )}
              </>
            )}

            {titleDescriptions?.map(({ Title }, idx) => (
              <SummaryField
                product={{ TitleDescriptions: titleDescriptions }}
                field={{
                  DisplayValue: Title,
                  State: "show",
                  Type: "text-input",
                  PropertyChain: `TitleDescriptions[${idx}].Description`,
                }}
              />
            ))}

            <Text
              css={`
                font-size: 0.8em;
                margin: 16px 0;
              `}
            >
              {product?.Disclaimer ? (
                <>
                  <p>{parseDisclaimer(product.Disclaimer)}</p>
                </>
              ) : (
                disclaimer
              )}
            </Text>
          </Div>
        </Div>
      </Modal>
    </Div>
  );
};

const detailTitlesByType = {
  long_term_disability: "Here's what's covered",
  short_term_disability: "Here's what's covered",
};

export const CardAlerts = {
  supplimental_life: ({ product, data }) => {
    // "Details.EmployerPaidCoverageAmount": {
    //   components: [
    //     ({ field }) => {
    //       const value = getFieldValue(product, field);

    const field = data?.["Details.EmployerPaidCoverageAmount"] || {};
    const value = getFieldValue(product, field);

    if (
      !product?.Details?.SupplementalLifeCoverageAvailable ||
      !product?.Details?.EmployerPaidCoverage
    ) {
      return null;
    }

    return (
      <Div
        css={css`
          ${flex("left")}
          padding: 16px;
          margin-bottom: 8px;
          background-color: ${colors.gray[100]};
          border-radius: 8px;
        `}
      >
        <WarningCircle color={colors.gray[500]} />
        <Div
          css={css`
            flex-grow: 1;
            padding-left: 16px;
            text-align: left;
            span {
              font-weight: bold;
              color: ${colors.black};
            }
          `}
        >
          <Text>
            Your employer covers up to{" "}
            <span>{getDisplayValue(value, field)}</span> of life insurance at no
            cost to you.
          </Text>
          <Text label>
            There is additional life insurance you should consider purchasing.
          </Text>
        </Div>
      </Div>
    );
  },
};
