import React, { Component } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { motion } from "framer-motion";
import BriteRadio from "./inputs/BriteRadio";

import { css } from "emotion";

export class DecisionToolSTDLTDQuestion extends Component {
  render() {
    return (
      <motion.div
        key={`DecisionToolSTDLTDQuestion_question${this.props.type}`}
        initial="pageInitial"
        animate="pageAnimate"
        exit="pageExit"
        variants={{
          pageInitial: {
            y: 400,
            opacity: 0,
          },
          pageAnimate: {
            y: 0,
            opacity: 1,
          },
          pageExit: {
            y: -400,
            opacity: 0,
          },
        }}
        className={css`
          background: #ffffff;

          box-shadow: 0px 2px 8px rgba(37, 40, 45, 0.25);
          border-radius: 8px;
          padding: 24px;
          margin-bottom: 32px;
          text-align: left;
        `}
      >
        <h1
          className={css`
            color: #25282d;
            font-size: 23px;
            line-height: 32px;
            margin-block-start: 0px;
            margin-block-end: 24px;
          `}
        >
          {" "}
          {this.props.type === "personal"
            ? this.props.isSurvey
              ? "If you were unable to work, how long could you go without a paycheck?"
              : "If you were unable to work, how long could you go without a paycheck?"
            : this.props.isSurvey
            ? "If you were unable to work, how long could you go without a paycheck?"
            : "If you were unable to work, how long could you go without a paycheck?"}
        </h1>
        <div
          className={css`
            margin-top: 25px;
          `}
        >
          <BriteRadio
            aria-label="DecisionToolSTDLTDQuestion"
            name="DecisionToolSTDLTDQuestion"
            value={this.props.value}
            options={[
              {
                value: "0",
                label: "0-3 months",
              },
              {
                value: "3",
                label: "3-6 months",
              },
              {
                value: "6",
                label: "6+ months",
              },
            ]}
            onClick={(value) => {
              this.props.setValue(value);
            }}
          />
        </div>
      </motion.div>
    );
  }
}
