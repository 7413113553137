import React from 'react';
import { getAttributes } from '../utils';

export const Website = ({ item, totalColumns }) => {

  const componentAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: 'component'
  });

  return (
    <div {...componentAttributes}>
      <iframe {...componentAttributes}>
      </iframe>
    </div>
  );
}