import { css, cx } from "emotion";
import { Component } from "./component";
import { getWidthValue } from "./utils";

const listContainer = css`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

export const ComponentContainer = (props) => {
  const { item, totalColumns } = props;
  const width = getWidthValue(item, totalColumns);
  const container = css`
    width: ${width * 100}%;
    box-sizing: border-box;
  `;

  return (
    <>
      {props?.item?.type === "list" ? (
        <div className={cx(listContainer, container, "component-container")}>
          {props?.item?.list?.map((item, idx) => {
            return <Component {...props} item={item} key={idx} />;
          })}
        </div>
      ) : (
        <div className={cx(container, "component-container")}>
          <Component {...props} />
        </div>
      )}
    </>
  );
};
