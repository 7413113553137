import { posthogAttrs } from "../../posthog-constants";

export const getWidthValue = (column, length) => {
  return "width" in column ? Number(column.width) : 1 / length;
};

export const formatString = (str, replace = "-") => {
  return str
    .split("")
    .map((letter, idx) => {
      return !/^\d+$/.test(letter) && letter.toUpperCase() === letter
        ? `${idx !== 0 ? replace : ""}${letter.toLowerCase()}`
        : letter;
    })
    .join("");
};

const camelCase = (value) => value.replace(/-./g, (x) => x[1].toUpperCase());

export const convertCssToObject = (cssText) => {
  if (cssText) {
    var regex = /([\w-]*)\s*:\s*([^;]*)/g;
    var match,
      properties = {};
    while ((match = regex.exec(cssText))) {
      const property = camelCase(match[1]);
      properties[property] = match[2].trim();
    }
    return properties;
  } else {
    return {};
  }
};

const additionalContainerStyles = {
  space: {
    height: "auto",
    overflow: "hidden",
  },
  document: { boxSizing: "border-box" },
};

const getStyles = ({ column, type, length }) => {
  if (type === "container") {
    const width = `${getWidthValue(column, length || 1) * 100}%`;

    const styles = additionalContainerStyles?.[column.component] || {};

    const combinedContainerStyles = {
      ...(column?.container?.attributes?.style || {}),
      ...styles,
      width,
    };
    const rawCss = convertCssToObject(column?.rawCss || "");
    return {
      ...combinedContainerStyles,
      ...rawCss,
    };
  } else if (type === "component") {
    return column?.attributes?.style || {};
  }
};

export const getClasses = (column, type) => {
  const component =
    column.component === "text" || column.component === "textV2"
      ? `text-${column.type}`
      : column.component;
  let classList = [`${component}-${type}`, column?.attributes?.className || ""];

  if (column.component === "text" && type === "container") {
    classList.push("flex-container");
  }
  if (column.hideOnDesktop) {
    classList.push("desktop-only");
  }
  if (column.hideOnMobile) {
    classList.push("mobile-only");
  }
  return classList.join(" ");
};

const kebabize = (str) =>
  str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? "-" : "") + $.toLowerCase()
  );

const extractObjectToDataAttributes = (obj, startKey) => {
  const dataAttribute = "data-ph-capture-attribute-" + startKey;
  return Object.entries(obj || {})?.reduce((prev, [property, item]) => {
    const key = `${dataAttribute}:${kebabize(property)}`;
    try {
      const value = item?.toString();
      return {
        ...prev,
        [key]: value,
      };
    } catch (err) {
      return {
        ...prev,
        [key]: `ERROR:${err?.message}`,
      };
    }
  }, {});
};

const getPosthogAttrs = (props) => {
  const { column } = props;
  let meta = {};
  try {
    switch (column?.component) {
      case "button": {
        const actionData = extractObjectToDataAttributes(
          column?.action?.data,
          "value"
        );
        meta = {
          "data-ph-capture-attribute-value:action-type": column?.action?.type,
          ...actionData,
        };
      }
    }
  } catch (err) {}

  return {
    ...meta,
    "data-ph-capture-attribute-source": posthogAttrs?.types?.CONTENT,
    "data-ph-capture-attribute-component": column?.component,
  };
};

export const getAttributes = (props) => {
  const { column, type, length } = props;
  const { container = {}, attributes = {} } = column;

  const posthog = props?.type === "component" ? getPosthogAttrs(props) : {};

  const attrType =
    type === "component" ? attributes : container?.attributes || {};

  const className = getClasses(column, type);
  const style = getStyles({ column, type, length });

  const obj = {
    ...posthog,
    ...attrType,
    className,
    style,
  };

  return obj;
};

export const buildCss = (styleObject, appliedStyles = "") =>
  Object.entries(styleObject || {}).reduce((p, [k, v]) => {
    const kebabKey = formatString(k);
    return p + ` ${kebabKey}:${v};`;
  }, appliedStyles);
