import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

const fpPromise = FingerprintJS.load({
    apiKey: "5fRe1luaAa7cM65Q1l4U",
    endpoint: ["https://fp.britehr.app", FingerprintJS.defaultEndpoint],
    scriptUrlPattern: ["https://fp.britehr.app/web/v<version>/<apiKey>/loader_v<loaderVersion>.js", FingerprintJS.defaultScriptUrlPattern],
});


let result;
async function getVisitor() {
    result = await (await fpPromise).get();
    return result;
}

export default getVisitor;