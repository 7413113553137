// EMPLOYEE-VIEW
import { cloneDeep } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { CustomAxios } from "../../../../axios/axios";
import { useStore } from "../../../store-provider/use-store";
import { getFieldsObject } from "./configs/config-utils";

const groupMultiNetwork = (productId, data) => {
  const product = data?.find(({ ID }) => ID === productId);
  if (product && product.MultiNetworkID) {
    let count = 1;
    const networks = data.reduce((prev, item) => {
      if (item?.Type === product?.Type) {
        count++;
      }
      if (item.MultiNetworkID === product.MultiNetworkID) {
        return { ...prev, [item.MultiNetworkCategory]: item };
      }
      return prev;
    }, {});
    return {
      ...networks,
      count,
      currentNetworkCategory: product?.MultiNetworkCategory || "core",
    };
  } else {
    const count = data?.reduce(
      (prev, { Type }) => prev + (product?.Type === Type ? 1 : 0),
      0
    );
    return {
      core: product || {},
      count,
      currentNetworkCategory: product?.MultiNetworkCategory || "core",
    };
  }
};

export const useProduct = ({ productId, productType }) => {
  const {
    data: { products, productLayouts, businessId },
  } = useStore();

  const [includedPrograms, setIncludedPrograms] = useState([]);

  const fetchIncludedPrograms = async () => {
    try {
      const { data } = await CustomAxios.get(
        `/public/v1/business/${businessId}/plans/${productId}/programs`
      );
      setIncludedPrograms(data);
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    fetchIncludedPrograms();
  }, [productId]);

  const { count, currentNetworkCategory, ...networks } = useMemo(() => {
    const clone = cloneDeep(products);
    return groupMultiNetwork(productId, clone);
  }, [products]);

  const product = networks?.[currentNetworkCategory] || {};
  const hasProductData = Object.keys(product || {})?.length;
  const layout = hasProductData
    ? { Sections: product?.sections }
    : { Sections: productLayouts?.[productType]?.Layout?.Sections };

  const fieldsObject = useMemo(() => getFieldsObject(layout?.Sections), [
    layout?.Sections,
  ]);

  const meta = {
    fieldsObject,
    isLoading: hasProductData && (!Object.keys(product)?.length || count === 0),
    productTypeCount: count,
    currentNetwork: currentNetworkCategory,
    // layoutDetail, TODO: ADD this for recommendations
    networks,
  };

  return {
    product,
    includedPrograms,
    layout,
    meta,
  };
};
