import { useEffect, useState } from "react";
import { CustomAxios } from "../../axios/axios";

export const useAxios = ({ url, enabled }) => {
  const [data, setData] = useState();

  const fetch = async () => {
    try {
      const response = await CustomAxios.get(url);
      setData(response.data);
    } catch (err) {
      console.log(err);
      setData();
    }
  };

  useEffect(() => {
    if (enabled) {
      fetch();
    }
  }, [url, enabled]);

  return {
    refetch: fetch,
    data,
    setData,
  };
};
