import React, { useEffect } from "react";
import { css } from "emotion";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAPIHook } from "../api-hooks/use-api-hook";

export const RegularPageViewer = (props) => {
  const { data, loading } = useAPIHook(
    `/public/v1/page/${props.currentPage.ID}/html`,
    "Could not load page, try again."
  );

  useEffect(() => {
    if (!props.handlePageHTMLLoaded) {
      return;
    }
    props.handlePageHTMLLoaded(data);
  }, [data]);

  if (loading) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: center;
          margin-top: 24px;
        `}
      >
        <CircularProgress />
      </div>
    );
  }

  return <div dangerouslySetInnerHTML={{ __html: data.HTMLContent }}></div>;
};
