import React, { useState, useEffect, useRef } from "react";

import { motion } from "framer-motion";
import { css } from "emotion";
import Typography from "@material-ui/core/Typography";
import {
  benefitListContainer,
  borderStyles,
  largeScreen,
  smallScreen,
} from "./product-components/shared-styles";
import { ProductRecWrapper } from "./ProductRevWrapper";
import { Text, Button } from "../../../shared-components";
import { colors } from "../../../shared-components/styles";
import { flex } from "../../../shared-components/shared-styles";
import { Check, Info, Minus, Plus, Star } from "phosphor-react";
import {
  ProductComparison,
  PRODUCT_HEADERS,
} from "../../brite-viewer/components/benefits/product-comparisons";
import { Collapse } from "@material-ui/core";

export const AdditionalBenefitsList = (props) => {
  const [selectableTypes, setSelectableTypes] = useState();
  const [compareType, setCompareType] = useState();

  const scrollRef = useRef();

  const data = Object.values(props?.productData || {});

  useEffect(() => {
    const suggestedProductTypes = Object.entries(props?.productData)?.reduce(
      (prev, [type, item]) => {
        const suggested = item?.find((item) => item?.Suggested);
        const hasSelected = item?.find((item) =>
          props?.selected?.includes(item?.ID)
        );
        if (suggested || hasSelected) {
          return [...prev, type];
        }
        return prev;
      },
      []
    );
    setSelectableTypes(suggestedProductTypes);
  }, [data?.length]);

  const toggleSelectableType = (type) => {
    if (selectableTypes.includes(type)) {
      const next = selectableTypes?.filter((item) => item !== type);
      setSelectableTypes(next);
    } else {
      setSelectableTypes([...selectableTypes, type]);
    }
  };

  const hasProducts = !!data?.length;

  if (!hasProducts) {
    return null;
  }

  return (
    <motion.div
      key={"benlist_question"}
      initial="pageInitial"
      animate="pageAnimate"
      exit="pageExit"
      variants={{
        pageInitial: {
          x: 400,
          opacity: 0,
        },
        pageAnimate: {
          x: 0,
          opacity: 1,
        },
        pageExit: {
          x: -400,
          opacity: 0,
        },
      }}
      className={benefitListContainer}
    >
      <div className={borderStyles} ref={scrollRef}>
        <Typography className="section-title">{props.title}</Typography>

        {Object.entries(props?.productData || {}).map(([type, list]) => {
          const hasRecommendation = list.some(({ Suggested }) => Suggested);
          const hasSelected = list.some(({ Product }) =>
            props.selected.includes(Product?.ID)
          );

          const hasSelectedType = selectableTypes?.includes(type);
          const ids = list?.map(({ Product }) => Product?.ID);

          return (
            <div
              className={css`
                border-radius: 16px;
                background-color: white;
                margin: 24px 0;
                border: 1px solid ${colors.gray[300]};
                padding: 32px;
                cursor: default;
              `}
              onClick={() => (hasSelected ? null : toggleSelectableType(type))}
            >
              <div
                className={css`
                  gap: 16px;
                  ${flex("jcsb ais")}
                  ${!hasSelected ? `cursor: pointer;` : ""}
                `}
              >
                <Text
                  h1
                  css={`
                    ${smallScreen} {
                      font-size: 24px;
                      line-height: 32px;
                      text-align: left;
                    }
                  `}
                >
                  {PRODUCT_HEADERS[type]}
                </Text>
                {hasRecommendation ? (
                  <div
                    className={css`
                      border-radius: 30px;
                      background-color: var(--button-background-color);
                      padding: 8px 16px;
                      ${flex("left")}
                    `}
                  >
                    <Star
                      size={16}
                      color="var(--button-text-color)"
                      weight="fill"
                    />
                    <Text
                      css={`
                        ${smallScreen} {
                          display: none;
                        }
                        color: var(--button-text-color);
                        margin-left: 8px;
                      `}
                    >
                      Recommended
                    </Text>
                  </div>
                ) : !hasSelected ? (
                  <Button
                    styles="icon"
                    css={`
                      ${largeScreen} {
                        width: 40px;
                        height: 40px;
                      }
                      ${smallScreen} {
                        width: 32px;
                        height: 32px;
                      }
                      background-color: ${colors.gray[200]};
                      color: ${colors.black};
                    `}
                  >
                    {!hasSelectedType ? <Plus /> : <Minus />}
                  </Button>
                ) : null}
              </div>
              <div
                className={css`
                  border-radius: 8px;
                  background-color: ${colors.gray[100]};
                  padding: 16px;
                  margin: 8px 0;
                  margin-top: 16px;
                  ${largeScreen} {
                    ${flex("left wrap")}
                  }
                  ${smallScreen} {
                    ${flex("center column")}
                    text-wrap: break-word;
                  }
                  p {
                    max-width: calc(100% - 64px);
                  }
                  svg {
                    margin-right: 16px;
                    min-width: 32px;
                  }
                `}
              >
                {!hasRecommendation ? (
                  <Info weight="fill" color="var(--button-background-color)" />
                ) : hasSelected ? (
                  <Check size={24} color="var(--button-background-color)" />
                ) : null}
                {!hasRecommendation ? (
                  <Text label>
                    No recommendations for {PRODUCT_HEADERS[type]} but you do
                    have options to consider.
                  </Text>
                ) : !hasSelected ? (
                  <Text label>
                    There {list?.length === 1 ? "is" : "are"} {list?.length}{" "}
                    {PRODUCT_HEADERS[type]} option
                    {list?.length === 1 ? "" : "s"}.{" "}
                    {list?.length > 1 ? "Select one that works for you." : ""}
                  </Text>
                ) : (
                  <Text label>{PRODUCT_HEADERS[type]} selected.</Text>
                )}
              </div>
              <Collapse in={hasSelected || hasSelectedType}>
                {list?.map((recommendation) => (
                  <ProductRecWrapper
                    key={recommendation?.Product?.ID}
                    productTypeIds={ids}
                    recommendation={recommendation}
                    selected={props.selected}
                    setSelected={props.setSelected}
                    isMulti={true}
                  />
                ))}
                {list?.length > 1 ? (
                  <div
                    className={css`
                      ${flex("center")}

                      width: 100%;
                    `}
                  >
                    <Button
                      secondary
                      className={css`
                        min-width: 100%;
                      `}
                      onClick={(e) => {
                        e.stopPropagation();
                        setCompareType(type);
                      }}
                    >
                      Compare Plans
                    </Button>
                  </div>
                ) : null}
              </Collapse>
            </div>
          );
        })}
      </div>

      {data?.length > 1 && compareType ? (
        <ProductComparison
          display={!!compareType}
          onClose={() => setCompareType("")}
          productType={compareType}
        />
      ) : null}
    </motion.div>
  );
};
