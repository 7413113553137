import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { BriteSelect } from "./inputs/BriteSelect";

import { css } from "emotion";

import { motion } from "framer-motion";

export class DecisionToolMedicalConditionsQuestion extends Component {
  render() {
    return (
      <motion.div
        key={`med-condisit_question${this.props.type}`}
        initial="pageInitial"
        animate="pageAnimate"
        exit="pageExit"
        variants={{
          pageInitial: {
            y: 400,
            opacity: 0,
          },
          pageAnimate: {
            y: 0,
            opacity: 1,
          },
          pageExit: {
            y: -400,
            opacity: 0,
          },
        }}
        className={css`
          box-shadow: 0px 2px 8px rgba(37, 40, 45, 0.25);
          border-radius: 8px;
          background: #ffffff;
          padding: 24px;
          margin-bottom: 32px;
          text-align: left;
        `}
      >
        <h1
          className={css`
            color: #25282d;
            font-size: 23px;
            line-height: 32px;
            margin-block-start: 0px;
            margin-block-end: 24px;
          `}
        >
          {this.props.type === "personal"
            ? "Are you currently receiving treatment for any of the below conditions?"
            : "Are your dependents receiving treatment for any of the below conditions?"}{" "}
        </h1>

        {this.props.shouldDisplayDependentQuestions && (
          <div
            className={css`
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              /* identical to box height, or 133% */

              letter-spacing: 0.15px;

              color: #25282d;
              margin-top: 16px;
            `}
          >
            For you
          </div>
        )}

        <BriteSelect
          MenuProps={{
            style: { zIndex: 35001 },
          }}
          className={css`
            min-width: 250px;
          `}
          labelId="conditions-label"
          id="conditions-label-id"
          value={this.props.medicalConditions}
          onChange={(e) => this.props.setMedicalConditions(e)}
          label="Select condition"
        >
          {[
            "None",
            "Kidney Dialysis",
            "Cancer",
            "Auto-immune Disorder",
            "Lung Disorders",
            "End-Stage Renal Failure",
          ].map((option) => {
            return (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            );
          })}
        </BriteSelect>

        {this.props.shouldDisplayDependentQuestions && (
          <>
            <div
              className={css`
                font-family: "Roboto";
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                /* identical to box height, or 133% */

                letter-spacing: 0.15px;

                color: #25282d;
                margin-top: 24px;
              `}
            >
              For your dependents
            </div>

            <BriteSelect
              MenuProps={{
                style: { zIndex: 35001 },
              }}
              className={css`
                min-width: 250px;
              `}
              labelId="conditions-label"
              id="conditions-label-id"
              value={this.props.dependantMedicalConditions}
              onChange={(e) => this.props.setDependantMedicalConditions(e)}
              label="Select condition"
            >
              {[
                "None",
                "Kidney Dialysis",
                "Cancer",
                "Auto-immune Disorder",
                "Lung Disorders",
                "End-Stage Renal Failure",
              ].map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </BriteSelect>
          </>
        )}

        <div
          className={css`
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            /* identical to box height, or 200% */

            letter-spacing: 0.15px;

            color: #9aa7b5;
            margin-top: 16px;
          `}
        >
          *Your answer to this question will not affect your rate
        </div>
      </motion.div>
    );
  }
}
