import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import { motion } from "framer-motion";
import { css, cx } from "emotion";
import parse from "html-react-parser";
import {
  smallScreen,
  benefitContainer,
  logoContainer,
} from "../additional-benefits/product-components/shared-styles";
import { CaretUp, CaretDown } from "phosphor-react";
import { Typography } from "@material-ui/core";
import { posthogAttrs } from "../../../posthog-constants";

export class DependantFSAPlanViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      learnMoreOpen: false,
      meaningsOpen: false,
    };
  }

  toggleLearnMore = () => {
    this.setState((prevState, props) => {
      return {
        learnMoreOpen: !prevState.learnMoreOpen,
      };
    });
  };

  getTitleDescription = () => {
    if (this.props.benefit && this.props.benefit.TitleDescriptions) {
      let customTitleDescriptions = [];
      for (let td of this.props.benefit.TitleDescriptions) {
        if (!td.Autogenerated) {
          customTitleDescriptions.push(td);
        }
      }
      return customTitleDescriptions;
    }
    return [];
  };

  hasTitleDescription = () => {
    return this.getTitleDescription().length > 0;
  };

  hasLogo = () => {
    if (this.props.carrier && this.props.carrier.LogoURL) {
      return true;
    }
    return false;
  };

  getLogo = () => {
    if (this.props.carrier && this.props.carrier.LogoURL) {
      return this.props.carrier.LogoURL;
    }
    return "";
  };

  replaceURLWithHTMLLinks(text) {
    var exp = /(?:^|[^"'])((ftp|http|https|file):\/\/[\S]+(\b|$))/gi;
    return text.replace(exp, " <a href='$1' target='_blank'>$1</a>");
  }

  parseDisclaimer = (disclaimer) => {
    return parse(this.replaceURLWithHTMLLinks(disclaimer));
  };

  render() {
    return (
      <motion.div
        key={"fasplan_question"}
        initial="pageInitial"
        animate="pageAnimate"
        exit="pageExit"
        variants={{
          pageInitial: {
            x: 400,
            opacity: 0,
          },
          pageAnimate: {
            x: 0,
            opacity: 1,
          },
          pageExit: {
            x: -400,
            opacity: 0,
          },
        }}
        className={cx(
          css`
            position: relative;
          `,
          benefitContainer
        )}
        style={{
          border: `4px solid ${
            this.props.designStyles?.Theme?.Body?.ButtonColor
              ? this.props.designStyles?.Theme?.Body?.ButtonColor
              : "#25282D"
          }`,
        }}
      >
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <div
            className={css`
              margin-right: 16px;
              max-width: 66%;
              @media only screen and (max-width: 600px) {
                min-width: 140px;
              }
            `}
          >
            <div>
              <Typography
                style={{
                  fontSize: smallScreen ? "16px" : "18px",
                  fontWeight: "bold",
                  marginBottom: "8px",
                  textAlign: smallScreen ? "left" : "center",
                }}
              >
                Dependent Care FSA
              </Typography>
              <Typography
                className={css`
                  font-size: 12px;
                  margin: 0;
                  padding: 0;

                  margin-right: 40px;
                  text-align: left;
                  @media only screen and (max-width: 600px) {
                    display: none;
                  }
                `}
              >
                Set aside pre-tax dollars for expenses like preschool, summer
                day camp, before or after school programs, and daycare.
              </Typography>
            </div>
          </div>
          <div
            className={cx(
              logoContainer,
              css`
                justify-content: center;
                align-items: center;
                ${smallScreen} {
                  flex-grow: 1;
                  justify-content: end;
                }
              `
            )}
          >
            {this.hasLogo() ? (
              <img src={this.getLogo()} alt="Carrier Logo" />
            ) : null}
          </div>
        </div>

        <div
          className={css`
            display: flex;
            align-items: center;
            @media only screen and (min-width: 600px) {
              display: none;
            }
          `}
        >
          <div
            className={css`
              font-size: 12px;
              margin: 0;
              padding: 0;
              flex-grow: 1;
              margin-right: 40px;
              text-align: left;
            `}
          >
            Set aside pre-tax dollars for expenses like preschool, summer day
            camp, before or after school programs, and daycare.
          </div>
        </div>

        {this.state.learnMoreOpen && (
          <>
            <div
              className={css`
                font-size: 10px;
                line-height: 1.5;
                padding: 10px;
                padding-top: 0px;
              `}
            >
              You can pair this plan with any of our medical plan offerings. A
              Dependent Care FSA allows you to set aside pre-tax dollars for
              expenses like preschool, summer day camp, before or after school
              programs, and daycare.
            </div>

            <div
              className={css`
                font-weight: bold;
                text-align: left;
                padding: 20px;
              `}
            >
              How much can you set aside annually?
            </div>
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-right: 40px;
                margin-left: 40px;
                margin-bottom: 16px;
                font-size: 12px;
              `}
            >
              <div
                className={css`
                  max-width: 40%;
                  text-align: left;
                `}
              >
                Married and file seperate tax return
              </div>
              <div>
                <div
                  className={css`
                    font-weight: bold;
                  `}
                >
                  $2,500
                </div>
              </div>
            </div>
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-right: 40px;
                margin-left: 40px;
                margin-bottom: 16px;
                font-size: 12px;
              `}
            >
              <div
                className={css`
                  max-width: 40%;
                  text-align: left;
                `}
              >
                Married with joint tax return, single, or head of household
              </div>
              <div>
                <div
                  className={css`
                    font-weight: bold;
                  `}
                >
                  $5,000
                </div>
              </div>
            </div>

            <div
              className={css`
                font-weight: bold;
                text-align: left;
                padding: 20px;
              `}
            >
              Why enroll in this program?
            </div>
            <div
              className={css`
                text-align: left;
                padding-left: 20px;
                padding-right: 20px;
              `}
            >
              Save an average of 30% on dependant child care services.{" "}
            </div>
            <div
              className={css`
                text-align: left;
                padding-left: 20px;
                padding-right: 20px;
              `}
            >
              Reduce your overall tax burden
            </div>
            <div
              className={css`
                text-align: left;
                padding-left: 20px;
                padding-right: 20px;
                margin-bottom: 24px;
              `}
            >
              Easy, no-hassle payments and reimbursement options
            </div>

            {this.hasTitleDescription() && (
              <>
                {this.getTitleDescription().map((td) => {
                  return (
                    <div
                      className={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-right: 40px;
                        margin-left: 40px;
                        margin-bottom: 16px;
                        font-size: 12px;
                      `}
                    >
                      <div
                        className={css`
                          max-width: 40%;
                          text-align: left;
                        `}
                      >
                        {td.Title}
                      </div>
                      <div>
                        <div
                          className={css`
                            font-weight: bold;
                          `}
                        >
                          {td.Description}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {this.props.benefit.Disclaimer && (
              <div
                className={css`
                  font-size: 9px;
                  font-weight: 400;
                  margin: 16px;
                `}
              >
                {this.parseDisclaimer(this.props.benefit.Disclaimer)}
              </div>
            )}
          </>
        )}

        <div
          className={css`
            display: flex;
            justify-content: center;
            margin-bottom: -20px;
          `}
        >
          <IconButton
            aria-label="Learn more"
            onClick={() => this.toggleLearnMore()}
            data-ph-capture-attribute-source={
              posthogAttrs?.types?.RECOMMENDATIONS
            }
            data-ph-capture-attribute-action={posthogAttrs?.actions?.VIEW_MORE}
            data-ph-capture-attribute-product-type={this?.props.benefit?.Type}
          >
            {!this.state.learnMoreOpen ? (
              <CaretDown size={24} />
            ) : (
              <CaretUp size={24} />
            )}
          </IconButton>
        </div>
      </motion.div>
    );
  }
}
