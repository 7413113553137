import { css } from "emotion";
import { Layer, Grommet } from "grommet";
import React, { forwardRef } from "react";
import { flex } from "./shared-styles";

export const theme = {
  layer: {
    container: { zIndex: 2000 },
    zIndex: 2000,
  },
};

export const Modal = forwardRef(
  ({ display, onClose, children, ...rest }, ref) => {
    if (!display) {
      return null;
    }

    return (
      <Grommet
        theme={theme}
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <Layer
          ref={ref}
          onEsc={onClose}
          modal={true}
          {...rest}
          className={css`
            ${css`
              ${rest?.className || ""}
            `}
            border-radius: 16px !important;
            ${flex("center start")}
          `}
        >
          {children}
        </Layer>
      </Grommet>
    );
  }
);
