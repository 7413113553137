import React, { Component } from "react";
import { motion } from "framer-motion";
import Button from "@material-ui/core/Button";
import { css } from "emotion";

export class DecisionToolIntroViewer extends Component {
  render() {
    return (
      <motion.div
        key={"intro_question"}
        initial="pageInitial"
        animate="pageAnimate"
        exit="pageExit"
        variants={{
          pageInitial: {
            opacity: 0,
          },
          pageAnimate: {
            opacity: 1,
          },
          pageExit: {
            x: -400,
            opacity: 0,
          },
        }}
        className={css`
          height: 100%;
          text-align: center;
          margin-bottom: 50px;
          padding-top: 10px;
        `}
      >
        <div
          className={css`
            margin: 12px;
            padding: 20px;
            border-radius: 10px;
            max-height: 600px;
          `}
        >
          <h1
            className={css`
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 34px;
              line-height: 48px;
              /* identical to box height, or 141% */

              text-align: center;

              color: #25282d;
            `}
          >
            {" "}
            Benefits Package Recommendations
          </h1>

          <p
            className={css`
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              /* or 150% */

              text-align: center;

              color: #36414c;
            `}
          >
            {this.props.userSelectedRecs
              ? "Your recommendations are ready!"
              : "Answer a few simple questions to receive your personalized recommendations."}
          </p>

          <div
            className={css`
              margin-top: 40px;
              margin-bottom: 20px;
            `}
          >
            <Button
              style={{
                background: "#25282D",
                color: "#fff",
                borderRadius: "8px",
                padding: "12px 42px",
                fontWeight: 700,
              }}
              id="decision-tool-begin"
              variant="contained"
              size="large"
              color="primary"
              onClick={() => {
                this.props.setDecisionToolOpen(true);
              }}
            >
              {this.props.userSelectedRecs
                ? "View your Recommendations"
                : "Get My Recommendations"}
            </Button>
          </div>
        </div>
      </motion.div>
    );
  }
}
