import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import { css } from "emotion";
import IconButton from "@material-ui/core/IconButton";
import { Text } from "../shared-components/text";

import { X } from "phosphor-react";

const useStyles = makeStyles({
  list: {
    width: 300,
    paddingBottom: "170px",
  },
  listPadding: { paddingTop: "0px !important" },
  fontHeader: {
    fontWeight: "bold",
    fontSize: "1.2em",
    color: "#25282d",
  },
  hide: {
    display: "none",
  },
  border: { borderLeft: "1px solid #D1DAE3 !important" },
  rotate: {
    transform: "rotate(-180deg)",
  },
  active: {
    backgroundColor: "#f5f7fa !important",
  },
});

export default function MenuDrawer(props) {
  const classes = useStyles();

  const toggleDrawer = (open, chosenPageIndex) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    props.setOpen(open);
    if (Number.isInteger(chosenPageIndex)) {
      props.setCurrentPageIndex(chosenPageIndex);
    }
  };

  return (
    <div className={classes.list} role="presentation">
      {props.title && (
        <div
          className={css`
            padding: 24px;
            padding-left: 32px;
            padding-bottom: 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <Text h3>{props.title}</Text>
        </div>
      )}
      <div
        className={css`
          padding: 24px;
          padding-left: 32px;
          padding-bottom: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <div
          onClick={toggleDrawer(false)}
          className={css`
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 175%;
            /* or 32px */

            color: #25282d;
          `}
        >
          Pages
        </div>

        {!props.isDesktop && (
          <IconButton
            id="navigation"
            color="inherit"
            aria-label="Toggle Navigation Menu"
            edge="end"
            onClick={toggleDrawer(false)}
          >
            <X />
          </IconButton>
        )}
      </div>

      <List className={classes.listPadding}>
        {props.pages.map((page, index) => (
          <ListItem
            className={
              "page-list-nav" +
              (props.currentPageIndex - 1 === index
                ? " page-nav-prev"
                : props.currentPageIndex + 1 === index
                ? " page-nav-next"
                : "")
            }
            style={{
              paddingLeft: "32px",
              paddingBottom: "12px",
              paddingTop: "12px",
            }}
            classes={{ selected: classes.active }}
            selected={index === props.currentPageIndex}
            button
            key={page.ID}
            onClick={toggleDrawer(props.isDesktop ? props.open : false, index)}
            onKeyDown={toggleDrawer(props.isDesktop ? props.open : false)}
          >
            <div
              className={css`
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 175%;
                /* identical to box height, or 28px */

                color: #25282d;
              `}
            >
              {page.Name}
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
