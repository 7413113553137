import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import { css } from "emotion";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Slide from "@material-ui/core/Slide";
import ListSubheader from "@material-ui/core/ListSubheader";
import { CustomAxios } from "../axios/axios.js";
import { MagnifyingGlass, X } from "phosphor-react";
import { useDebounce } from "./brite-viewer/use-debounce";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  search: {
    position: "relative",
    flex: 1,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "transparent",

    display: "flex",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    flex: 1,
  },
  primary: {
    fontFamily: "Roboto",

    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "24px",

    letterSpacing: "0.15px",

    color: "#25282D",
  },

  secondary: {
    fontFamily: "Roboto",

    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",

    letterSpacing: "0.15px",

    color: "#66737F",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(5)}px)`,

    transition: theme.transitions.create("width"),
    width: "100%",
    flex: 1,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px",
    /* identical to box height, or 150% */

    letterSpacing: 0.15,

    color: "#25282D",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function strip(html, json) {
  // some of our pages don't have HTMLContent, so we'll just search the JSON content as a work-around for now.
  if (!html) {
    return JSON.stringify(json);
  }
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

export default function SearchDesktop(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = useState("");
  const [filteredStates, setFilteredStates] = useState([]);

  const recordSearch = useDebounce(async () => {
    if (!search) {
      return;
    }
    try {
      const resp = await CustomAxios.get(
        `/public/v1/guide/${props.courseId}/search?search=${search}`
      );
    } catch (e) {
      console.log("error recording search", e);
    }
  }, 2000);

  useEffect(() => {
    const timer = setTimeout(() => {
      let filter = props.pages.filter((page) => {
        const strippedString = strip(page.HTMLContent, page.Content);

        return strippedString
          .toLowerCase()
          .includes(search.toLowerCase().trim());
      });

      for (let page of filter) {
        const strippedString = strip(page.HTMLContent, page.Content);
        if (!page.HTMLContent) {
          continue;
        }
        let indexOfSearchTerm = strippedString
          .toLowerCase()
          .indexOf(search.toLowerCase().trim());
        let lengthOfContent = strippedString.length;
        let startIndex = indexOfSearchTerm - 40;
        let endIndex = indexOfSearchTerm + 40;
        let endSet = false;
        if (endIndex > lengthOfContent) {
          endIndex = lengthOfContent - 1;
          endSet = true;
        }
        if (startIndex < 0) {
          startIndex = 0;
        }

        let excerpt = strippedString.substring(startIndex, endIndex);

        if (startIndex !== 0) {
          excerpt = `...${excerpt}`;
        }

        if (!endSet) {
          excerpt = `${excerpt}...`;
        }

        page.excerpt = excerpt;
      }

      if (!search) {
        filter = [];
      }

      recordSearch();

      setFilteredStates(filter);
    }, 500);

    return () => clearTimeout(timer);
  }, [search]);

  const handleClose = () => {
    setOpen(false);
    setSearch("");
    props.setSearchDesktopOpen(false);
  };

  const goToPage = (order) => {
    let index = 0;
    for (let page of props.pages) {
      if (page.Order === order) {
        break;
      }
      index++;
    }
    props.setCurrentPageIndex(index);
    setOpen(false);
    setSearch("");
    props.setSearchDesktopOpen(false);
  };

  return (
    <div
      className={css`
        flex: 1;
        max-width: 768px;
        margin-left: 32px;
        margin-right: 32px;
      `}
    >
      <div className={classes.appBar} color="transparent" elevation="0">
        <div
          className={css`
            display: flex;
          `}
        >
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <MagnifyingGlass />
            </div>
            <InputBase
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              autoFocus
              inputProps={{ "aria-label": "search" }}
            />
          </div>

          <IconButton
            edge="start"
            aria-label="Close Search"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <X />
          </IconButton>
        </div>
      </div>

      {search && (
        <div
          className={css`
            position: fixed;
            top: 108px;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 999999;
            background: #ffffff;
            width: 100%;
            max-width: 736px;
            margin-left: 24px;

            box-shadow: 0px 2px 8px rgba(37, 40, 45, 0.25);
            border-radius: 16px;
            max-height: 400px;
            overflow: auto;
          `}
        >
          <List
            style={{ marginTop: "32px", marginBottom: "48px" }}
            subheader={
              <ListSubheader
                disableSticky={true}
                component="div"
                id="nested-list-subheader"
                style={{
                  paddingLeft: "28px",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",

                  letterSpacing: "0.15px",

                  color: "#66737F",
                  marginBottom: "16px",
                }}
              >
                Search Results
              </ListSubheader>
            }
          >
            {search && filteredStates && filteredStates.length === 0 && (
              <ListItem
                style={{
                  padding: "16px",
                  paddingLeft: "28px",
                  paddingRight: "28px",
                }}
                button
                onClick={() => {}}
              >
                <ListItemText
                  primary={"No Results"}
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secondary,
                  }}
                />
              </ListItem>
            )}
            {filteredStates.map((page) => {
              return (
                <React.Fragment key={page.ID}>
                  <ListItem
                    style={{
                      padding: "16px",
                      paddingLeft: "28px",
                      paddingRight: "28px",
                    }}
                    button
                    onClick={() => {
                      goToPage(page.Order);
                    }}
                  >
                    <ListItemText
                      classes={{
                        primary: classes.primary,
                        secondary: classes.secondary,
                      }}
                      primary={page.Name}
                      secondary={page.excerpt}
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })}
          </List>
        </div>
      )}
    </div>
  );
}
