import React, { useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

import { css } from "emotion";

import { motion } from "framer-motion";
import { BriteSelect } from "./inputs/BriteSelect";

export const CustomQuestionsPage = (props) => {
  const [value, setValue] = React.useState([]);
  React.useEffect(() => {
    setValue([]);
  }, [props.customQuestion.ID]);
  return (
    <motion.div
      key={`custom-questions-question${props.customQuestion.ID}`}
      initial="pageInitial"
      animate="pageAnimate"
      exit="pageExit"
      variants={{
        pageInitial: {
          y: 400,
          opacity: 0,
        },
        pageAnimate: {
          y: 0,
          opacity: 1,
        },
        pageExit: {
          y: -400,
          opacity: 0,
        },
      }}
      className={css`
        background: #fff;
        box-shadow: 0px 2px 8px rgba(37, 40, 45, 0.25);
        border-radius: 8px;
        padding: 24px;
        margin-bottom: 32px;
        text-align: left;
      `}
    >
      <h1
        className={css`
          color: #25282d;
          font-size: 23px;
          line-height: 32px;
          margin-block-start: 0px;
          margin-block-end: 24px;
        `}
      >
        {props.customQuestion.Text}
      </h1>

      <BriteSelect
        MenuProps={{
          style: { zIndex: 35001 },
        }}
        multiple
        className={css`
          min-width: 250px;
          max-width: 300px;
        `}
        labelId="custom-questions-label"
        id="custom-questions-label-id"
        value={value}
        onChange={(e) => {
          props.setAnswer(e.target.value);
          setValue(e.target.value);
        }}
        label="Select answer"
        renderValue={(selected) => {
          return selected.map((s) => s.Text).join(", ");
        }}
      >
        {props.customQuestion.Responses &&
          props.customQuestion.Responses.map((response) => {
            return (
              <MenuItem key={response.Text} value={response}>
                <Checkbox checked={value.indexOf(response) > -1} />
                <ListItemText primary={response.Text} />
              </MenuItem>
            );
          })}
      </BriteSelect>
    </motion.div>
  );
};
