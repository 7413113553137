const defaultFieldConfig = {
  // Applicable to form only
  hideEditLayout: false,
  disabled: false,
  needsAttention: false,
  productTrigger: null,
  storeValueAs: "string",
  roundUp: true,

  // Applicable to all config types
  hideField: false,
  components: [],
  aboveTheFold: false,
};

// - components:
//      An array of components to render instead of using the
//      default field type components.

//  - hideEditLayout:
//      Hide the ability to edit show/hide

//  - hideField:
//      Hide entire field

//  - disabled:
//      Disable the field but keep it shown

//  - needsAttention:
//      Highlight fields that are "required"

//  - storeValueAs
//      This property tells how we should format
//      a value before sending it to the backend
//      OPTIONS: 'string' | 'number'

//  - productTrigger:
//      A function that detects changes to the the PRODUCT's
//      PropertyChain value and can update other fields LAYOUT
//      properties

// - aboveTheFold:
//      Whether the field should be above the more details

export const getFieldConfigs = (config, { product, fieldsObject }) => {
  const defaultConfigFn =
    "defaultConfig" in config ? config.defaultConfig : null;
  const defaultConfig = defaultConfigFn
    ? defaultConfigFn({ product, fieldsObject })
    : {};
  const configFn = product?.Type in config ? config[product?.Type] : null;
  const productTypeConfig = configFn ? configFn({ product, fieldsObject }) : {};

  return Object.entries({
    ...defaultConfig,
    ...productTypeConfig,
  })?.reduce((prev, [key, value]) => {
    return {
      ...prev,
      [key]: {
        ...defaultFieldConfig,
        ...value,
      },
    };
  }, {});
};

export const getSectionConfig = (configuration, section) => {
  const config =
    section?.DisplayValue in configuration
      ? configuration?.[section?.DisplayValue]
      : {};
  const defaultConfig =
    configuration?.defaultConfig?.(section)?.[section?.DisplayValue] || {};
  return {
    ...defaultConfig,
    ...config,
  };
};

export const getFieldsObject = (sections) => {
  return sections?.length
    ? sections?.reduce((prev, section, sectionIdx) => {
        return section.Fields?.reduce(
          (p, field, fieldIdx) => ({
            ...p,
            [field?.PropertyChain]: {
              ...field,
              fieldIdx,
              sectionIdx,
            },
          }),
          prev
        );
      }, {})
    : {};
};
