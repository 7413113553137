import React, { Component } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { motion } from "framer-motion";
import BriteRadio from "./inputs/BriteRadio";

import { css } from "emotion";

export class DecisionToolSUPPQuestion extends Component {
  render() {
    return (
      <motion.div
        key={`DecisionToolSUPPQuestion_${this.props.type}`}
        initial="pageInitial"
        animate="pageAnimate"
        exit="pageExit"
        variants={{
          pageInitial: {
            y: 400,
            opacity: 0,
          },
          pageAnimate: {
            y: 0,
            opacity: 1,
          },
          pageExit: {
            y: -400,
            opacity: 0,
          },
        }}
        className={css`
          background: #ffffff;

          box-shadow: 0px 2px 8px rgba(37, 40, 45, 0.25);
          border-radius: 8px;
          padding: 24px;
          margin-bottom: 32px;
          text-align: left;
        `}
      >
        <h1
          className={css`
            color: #25282d;
            font-size: 23px;
            line-height: 32px;
            margin-block-start: 0px;
            margin-block-end: 24px;
          `}
        >
          {" "}
          {this.props.type === "personal"
            ? this.props.isSurvey
              ? "Do you have enough money to cover final expenses or support your surviving relatives should you pass away?"
              : "Do you have enough money to cover final expenses or support your surviving relatives should you pass away?"
            : this.props.isSurvey
            ? "Do you have enough money to cover final expenses or support your surviving relatives should you pass away?"
            : "Do you have enough money to cover final expenses or support your surviving relatives should you pass away?"}
        </h1>
        <div
          className={css`
            margin-top: 25px;
          `}
        >
          <BriteRadio
            aria-label="supp life"
            name="supp"
            value={this.props.value}
            options={[
              {
                value: true,
                label: "Yes",
              },
              {
                value: false,
                label: "No",
              },
            ]}
            onClick={(value) => {
              this.props.setValue(value);
            }}
          />
        </div>
      </motion.div>
    );
  }
}
