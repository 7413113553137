import React, { useReducer } from "react";

export const initialState = {
  benefitsPackage: [],
  pages: [],
  selectedBenefits: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "RESET": {
      return initialState;
    }

    case "UPDATE": {
      return {
        ...state,
        [action.key]: {
          ...((action.key in state && state[action.key]) || {}),
          ...action.payload,
        },
      };
    }

    case "SET": {
      return {
        ...state,
        [action.key]: action.payload,
      };
    }

    default:
      return state;
  }
};

export const StoreContext = React.createContext();

export const StoreProvider = (props) => {
  const [store, dispatchStore] = useReducer(reducer, initialState);
  return (
    <StoreContext.Provider value={{ store, dispatchStore }}>
      {props.children}
    </StoreContext.Provider>
  );
};
