export const states = [
  { country: "US", id: "AL", label: "Alabama" },
  { country: "US", id: "AK", label: "Alaska" },
  { country: "US", id: "AZ", label: "Arizona" },
  { country: "US", id: "AR", label: "Arkansas" },
  { country: "US", id: "CA", label: "California" },
  { country: "US", id: "CO", label: "Colorado" },
  { country: "US", id: "CT", label: "Connecticut" },
  { country: "US", id: "DE", label: "Delaware" },
  { country: "US", id: "DC", label: "District of Columbia" },
  { country: "US", id: "FL", label: "Florida" },
  { country: "US", id: "GA", label: "Georgia" },
  { country: "US", id: "HI", label: "Hawaii" },
  { country: "US", id: "ID", label: "Idaho" },
  { country: "US", id: "IL", label: "Illinois" },
  { country: "US", id: "IN", label: "Indiana" },
  { country: "US", id: "IA", label: "Iowa" },
  { country: "US", id: "KS", label: "Kansas" },
  { country: "US", id: "KY", label: "Kentucky" },
  { country: "US", id: "LA", label: "Louisiana" },
  { country: "US", id: "ME", label: "Maine" },
  { country: "US", id: "MD", label: "Maryland" },
  { country: "US", id: "MA", label: "Massachusetts" },
  { country: "US", id: "MI", label: "Michigan" },
  { country: "US", id: "MN", label: "Minnesota" },
  { country: "US", id: "MS", label: "Mississippi" },
  { country: "US", id: "MO", label: "Missouri" },
  { country: "US", id: "MT", label: "Montana" },
  { country: "US", id: "NE", label: "Nebraska" },
  { country: "US", id: "NV", label: "Nevada" },
  { country: "US", id: "NH", label: "New Hampshire" },
  { country: "US", id: "NJ", label: "New Jersey" },
  { country: "US", id: "NM", label: "New Mexico" },
  { country: "US", id: "NY", label: "New York" },
  { country: "US", id: "NC", label: "North Carolina" },
  { country: "US", id: "ND", label: "North Dakota" },
  { country: "US", id: "OH", label: "Ohio" },
  { country: "US", id: "OK", label: "Oklahoma" },
  { country: "US", id: "OR", label: "Oregon" },
  { country: "US", id: "PA", label: "Pennsylvania" },
  { country: "US", id: "PR", label: "Puerto Rico" },
  { country: "US", id: "RI", label: "Rhode Island" },
  { country: "US", id: "SC", label: "South Carolina" },
  { country: "US", id: "SD", label: "South Dakota" },
  { country: "US", id: "TN", label: "Tennessee" },
  { country: "US", id: "TX", label: "Texas" },
  { country: "US", id: "UT", label: "Utah" },
  { country: "US", id: "VT", label: "Vermont" },
  { country: "US", id: "VA", label: "Virginia" },
  { country: "US", id: "WA", label: "Washington" },
  { country: "US", id: "WV", label: "West Virginia" },
  { country: "US", id: "WI", label: "Wisconsin" },
  { country: "US", id: "WY", label: "Wyoming" },
];

export const theme = {
  layer: {
    container: { zIndex: 2000 },
    zIndex: 2000,
  },
  accordion: {
    heading: { level: "6" },
    icons: {
      color: "white",
    },
    border: undefined,
  },
  global: {
    colors: {
      brand: "#3A4684", //purple
      "accent-1": "#70C8B6", //green
      "accent-2": "#F26C6D", //pink
      "accent-3": "#FCF081", //yellow
      "accent-4": "#C2E8F6", //blue
    },
    font: {
      family: "Roboto",
      size: "14px",
      height: "20px",
    },
  },
};

export const extendedStates = [
  { country: "US", id: "ALL", label: "All" },
  { country: "US", id: "AL", label: "Alabama" },
  { country: "US", id: "AK", label: "Alaska" },
  { country: "US", id: "AZ", label: "Arizona" },
  { country: "US", id: "AR", label: "Arkansas" },
  { country: "US", id: "CA", label: "California" },
  { country: "US", id: "CO", label: "Colorado" },
  { country: "US", id: "CT", label: "Connecticut" },
  { country: "US", id: "DE", label: "Delaware" },
  { country: "US", id: "DC", label: "District of Columbia" },
  { country: "US", id: "FL", label: "Florida" },
  { country: "US", id: "GA", label: "Georgia" },
  { country: "US", id: "HI", label: "Hawaii" },
  { country: "US", id: "ID", label: "Idaho" },
  { country: "US", id: "IL", label: "Illinois" },
  { country: "US", id: "IN", label: "Indiana" },
  { country: "US", id: "IA", label: "Iowa" },
  { country: "US", id: "KS", label: "Kansas" },
  { country: "US", id: "KY", label: "Kentucky" },
  { country: "US", id: "LA", label: "Louisiana" },
  { country: "US", id: "ME", label: "Maine" },
  { country: "US", id: "MD", label: "Maryland" },
  { country: "US", id: "MA", label: "Massachusetts" },
  { country: "US", id: "MI", label: "Michigan" },
  { country: "US", id: "MN", label: "Minnesota" },
  { country: "US", id: "MS", label: "Mississippi" },
  { country: "US", id: "MO", label: "Missouri" },
  { country: "US", id: "MT", label: "Montana" },
  { country: "US", id: "NE", label: "Nebraska" },
  { country: "US", id: "NV", label: "Nevada" },
  { country: "US", id: "NH", label: "New Hampshire" },
  { country: "US", id: "NJ", label: "New Jersey" },
  { country: "US", id: "NM", label: "New Mexico" },
  { country: "US", id: "NY", label: "New York" },
  { country: "US", id: "NC", label: "North Carolina" },
  { country: "US", id: "ND", label: "North Dakota" },
  { country: "US", id: "OH", label: "Ohio" },
  { country: "US", id: "OK", label: "Oklahoma" },
  { country: "US", id: "OR", label: "Oregon" },
  { country: "US", id: "PA", label: "Pennsylvania" },
  { country: "US", id: "PR", label: "Puerto Rico" },
  { country: "US", id: "RI", label: "Rhode Island" },
  { country: "US", id: "SC", label: "South Carolina" },
  { country: "US", id: "SD", label: "South Dakota" },
  { country: "US", id: "TN", label: "Tennessee" },
  { country: "US", id: "TX", label: "Texas" },
  { country: "US", id: "UT", label: "Utah" },
  { country: "US", id: "VT", label: "Vermont" },
  { country: "US", id: "VA", label: "Virginia" },
  { country: "US", id: "WA", label: "Washington" },
  { country: "US", id: "WV", label: "West Virginia" },
  { country: "US", id: "WI", label: "Wisconsin" },
  { country: "US", id: "WY", label: "Wyoming" },
];

export const INTRO_DECISION_PAGE = 0;
export const STATE_QUESTION_PAGE = 1;
export const ENROLLING_QUESTION_PAGE = 2;
export const EXPLAIN_PERSONAL_FIRST_PAGE = 3;
//medical questions
export const PERSONAL_DOCTER_VISITS_QUESTION_PAGE = 4;
export const PERSONAL_MEDS_QUESTION_PAGE = 5;
export const PERSONAL_URGENT_CARE_QUESTION_PAGE = 6;
export const PERSONAL_BABY_QUESTION_PAGE = 7;
export const PERSONAL_MEDICAL_CONDITIONS_QUESTION_PAGE = 8;
export const PERSONAL_SURGERY_QUESTION_PAGE = 9;

export const PERSONAL_DENTAL_QUESTION_PAGE = 10;
export const PERSONAL_VISION_QUESTION = 11;
export const PERSONAL_STDLTD_QUESTION_PAGE = 12;
export const PERSONAL_SUPPLIFE_QUESTION = 13;

export const EXPLAIN_DEPENDANT_QUESTIONS_PAGE = 14;

export const DEPENDANT_DOCTER_VISITS_QUESTION_PAGE = 15;
export const DEPENDANT_MEDS_QUESTION_PAGE = 16;
export const DEPENDANT_URGENT_CARE_QUESTION_PAGE = 17;
export const DEPENDANT_BABY_QUESTION_PAGE = 18;
export const DEPENDANT_MEDICAL_CONDITIONS_QUESTION_PAGE = 19;
export const DEPENDANT_SURGERY_QUESTION_PAGE = 20;

export const DEPENDANT_DENTAL_QUESTION_PAGE = 21;
export const DEPENDANT_VISION_QUESTION = 22;

export const FAMILY_HISTORY_CONDITIONS_QUESTION_PAGE = 23;
export const CUSTOM_QUESTIONS_PAGE = 24;
export const DISCLAIMER_PAGE = 25;
export const RECS = 26;
export const EMAIL_REC = 27;
export const SURVEY_END = 28;

export const BRITE_RECS = "brite-recommendations";
export const BRITE_USER_RECS = "brite-user-recommendations";
export const BRITE_USER_ANSWERS = "brite-user-answers";
