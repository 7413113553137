import React from "react";
import { getAttributes } from "../utils";
import { css, cx } from "emotion";

export const Text = ({ item, totalColumns }) => {
  const componentAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: "component",
  });

  const combineComponentClass = cx(
    componentAttributes.className,
    css`
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      line-height: 1.2em;
      p {
        display: inline-block;
        line-height: 1.2em;
        width: 100%;
        margin: 0;
        padding: 0;
      }
      ol,
      ul {
        margin: 0;
        line-height: 0;
      }
      li {
        margin: 0;
        line-height: 1.5em;
        white-space: pre-line;
      }
    `
  );
  return (
    <pre
      {...componentAttributes}
      className={combineComponentClass}
      dangerouslySetInnerHTML={{ __html: item?.content || "" }}
    ></pre>
  );
};
