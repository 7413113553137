import { css } from "emotion";
import { CircularProgress } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { PDFPage } from "./PDFPage";
import { usePDF } from "../../api-hooks/usePDF";
import { CustomAxios } from "../../axios/axios";
import { theme } from "../../mui-theme";
import { Div } from "../../shared-components";

export const PDFViewer = ({ guideId }) => {
  const { pages, loading } = usePDF({ guideId });

  return (
    <ThemeProvider theme={theme}>
      <Div
        css={css`
          background: white;
          height: 100%;
          font-family: "Roboto", "Montserrat", sans-serif;
          overflow: visible;
          .page-nav-next {
            :disabled {
              opacity: 0.5;
            }
          }
        `}
      >
        <Div
          css={css`
            display: flex;
            flex-direction: column;
            height: 100%;
          `}
        >
          {loading ? (
            <Div
              css={css`
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <CircularProgress />
            </Div>
          ) : (
            <main
              id="main-content"
              className={css`
                flex: auto;
                overflow-y: visible;
              `}
            >
              <link
                rel="stylesheet"
                href={`${CustomAxios.baseURL()}public/v1/course/${
                  pages[0]?.CourseID
                }/theme-css`}
              />
              {pages?.length !== 0 &&
                pages.map((page, index) => <PDFPage page={page} key={index} />)}
            </main>
          )}
        </Div>
      </Div>
    </ThemeProvider>
  );
};
