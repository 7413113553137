import React, { useCallback, useMemo, useState } from "react";
import parse from "html-react-parser";

import { motion } from "framer-motion";
import { css, cx } from "emotion";
import { Typography } from "@material-ui/core";
import {
  itemContainer,
  largeScreen,
  listContainer,
  smallScreen,
} from "./additional-benefits/product-components/shared-styles";
import { CarrierLogo } from "../brite-viewer/components/benefits/carrier-logo";
import { posthogAttrs } from "../../posthog-constants";
import { colors } from "../../shared-components/styles";
import { container, flex } from "../../shared-components/shared-styles";
import { Text, Button, Modal } from "../../shared-components";
import { X } from "phosphor-react";

const toggleList = (list, value) => {
  if (list.includes(value)) {
    return list.filter((item) => item !== value);
  }
  return [...list, value];
};

export const CustomBenefit = (props) => {
  const { product } = props;
  const [learnMore, setLearnMore] = useState([]);

  const replaceURLWithHTMLLinks = (text) => {
    var exp = /(?:^|[^"'])((ftp|http|https|file):\/\/[\S]+(\b|$))/gi;
    return text.replace(exp, " <a href='$1' target='_blank'>$1</a>");
  };

  const parseDisclaimer = (disclaimer) => {
    return parse(replaceURLWithHTMLLinks(disclaimer));
  };

  const Logo = useCallback(
    ({ className }) => {
      return product?.ProviderID &&
        product?.ProviderID !== "00000000-0000-0000-0000-000000000000" ? (
        <div
          className={cx(
            className,
            css`
              border: 1px solid ${colors.gray[300]};
              border-radius: 8px;
              min-width: 164px;
              max-width: 164px;
              background-color: white;
              ${flex("center")}
            `
          )}
        >
          <CarrierLogo
            carrierID={product?.ProviderID}
            planCarrierName={product?.ProviderName}
            maxHeight="80px"
            position="center"
          />
        </div>
      ) : null;
    },
    [product?.ProviderID]
  );

  const titleDescriptions = useMemo(() => {
    if (product && product.TitleDescriptions) {
      return product.TitleDescriptions.filter(
        (item) => !item.Autogenerated && !!item.Title && !!item.Description
      );
    }
    return [];
  }, [product]);

  const isApl = product.ProviderID === "3224798f-7456-4b8a-8c2f-69dfd3a95686";

  return (
    <motion.div
      key={`custom-benefit-${product.ID}`}
      initial="pageInitial"
      animate="pageAnimate"
      exit="pageExit"
      variants={{
        pageInitial: {
          x: 400,
          opacity: 0,
        },
        pageAnimate: {
          x: 0,
          opacity: 1,
        },
        pageExit: {
          x: -400,
          opacity: 0,
        },
      }}
    >
      <div
        className={css`
          border-radius: 16px;
          border: 1px solid ${colors.gray[300]};
          padding: 32px;
          background-color: white;
          margin-bottom: 24px;
          position: relative;
          ${container.hover}
          ${props?.selected?.includes(props?.product?.ID)
            ? "outline: 4px solid var(--button-background-color); border: 1px solid transparent;"
            : container.hover}
          ${props?.recommended ? `` : ""}
          .large-screen {
            margin-left: 40px;
            ${smallScreen} {
              display: none;
            }
          }
          .small-screen {
            ${largeScreen} {
              display: none;
            }
          }
        `}
        onClick={() =>
          props.setSelected(toggleList(props.selected, props?.product?.ID))
        }
      >
        <div
          className={css`
            ${flex("space-between start")}
            ${smallScreen} {
              ${flex("center")}
            }
          `}
        >
          <div>
            <Text h1>{product.Title || product.ProductName}</Text>
            <Logo product={product} className="small-screen" />
            <Text>{product.Subtitle || product.Description}</Text>
          </div>
          <Logo product={product} className="large-screen" />
        </div>

        <div
          className={css`
            ${flex("space-between")}
            margin-top: 32px;
          `}
        >
          <Button
            css={`
              border: none;
              background: none;
              text-transform: none;
              text-decoration: underline;
              color: ${colors.black};
              padding: 0;
              font-size: 14px;
              line-height: 24px;
            `}
            onClick={(e) => {
              e.stopPropagation();
              setLearnMore(toggleList(learnMore, product?.ID));
            }}
            data-ph-capture-attribute-source={
              posthogAttrs?.types?.RECOMMENDATIONS
            }
            data-ph-capture-attribute-action={posthogAttrs?.actions?.VIEW_MORE}
            data-ph-capture-attribute-product-type={product?.Type}
            data-ph-capture-attribute-product-id={product?.ID}
          >
            Learn More
          </Button>
          {product.CallToActionUrl && product.CallToActionText ? (
            <Button
              css={`
                background-color: var(--button-background-color);
                color: var(--button-text-color);
              `}
              aria-label="Call To Action"
              data-ph-capture-attribute-source={
                posthogAttrs?.types?.RECOMMENDATIONS
              }
              data-ph-capture-attribute-action={
                posthogAttrs?.actions?.CALL_TO_ACTION
              }
              data-ph-capture-attribute-product-type={product?.Type}
              data-ph-capture-attribute-product-id={product?.ID}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.open(product.CallToActionUrl, "_blank");
              }}
            >
              {product.CallToActionText}
            </Button>
          ) : null}
        </div>
      </div>
      <Modal
        display={learnMore?.includes(product?.ID)}
        onClose={() => setLearnMore(toggleList(learnMore, product?.ID))}
      >
        <div
          className={css`
            width: 500px;
            padding: 32px;
            max-height: 60vh;
            overflow-y: auto;
          `}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={css`
              ${flex("space-between")}
            `}
          >
            <Text h2>{product?.Title || product?.ProductName}</Text>
            <Button
              styles="icon"
              onClick={() => setLearnMore(toggleList(learnMore, product?.ID))}
            >
              <X />
            </Button>
          </div>
          <Text>{product.Description}</Text>

          {!!titleDescriptions.length && (
            <div className={cx(listContainer, css``)}>
              {titleDescriptions.map((item) => (
                <div
                  className={itemContainer}
                  key={item.Title + item.Description}
                >
                  <Typography>{item.Title}</Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {item.Description}
                  </Typography>
                </div>
              ))}
            </div>
          )}

          {!isApl && (
            <Text
              css={`
                margin: 16px 0;
              `}
            >
              *There are other aspects of this benefit to consider, including
              pricing, coverage limitations, exclusions, reductions and other
              ways this benefit will or will not pay. Please refer to your plan
              documents for full coverage.
            </Text>
          )}

          {product.Disclaimer && (
            <Text
              style={{ marginTop: "16px", fontSize: "12px" }}
              color="secondary"
            >
              {parseDisclaimer(product.Disclaimer)}
            </Text>
          )}
        </div>
      </Modal>
    </motion.div>
  );
};
