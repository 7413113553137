import { useMemo } from "react";
import { BenefitSummary } from "./summaries/benefit-summary";
import { PricingSummary } from "./summaries/pricing-summary";
import { NetworkSearch } from "./summaries/network-search";
import { useProduct } from "./use-product";
import { CostACA } from "./cost-aca";
import { container } from "../../../../shared-components/shared-styles";
import { Div, Text } from "../../../../shared-components";
import { css } from "emotion";
import { PRODUCT_HEADERS } from "./product-comparisons";
import { colors } from "../../../../shared-components/styles";

export const Benefits = ({ item }) => {
  const { component, data, type } = item;
  const product = useProduct({
    productId: data?.id,
    productType: type,
  });

  const Summary = useMemo(() => {
    if (component === "benefits") {
      return <BenefitSummary data={product} />;
    } else if (component === "pricing") {
      const premiumsSetExternally = product?.product?.Details?.PlanType?.startsWith(
        "aca"
      );
      const props = {
        data: product,
        summary: data,
        type: type,
        premiumsSetExternally,
      };
      return <PricingSummary {...props} />;
    } else if (component === "network") {
      return <NetworkSearch data={product} summary={data} type={type} />;
    }
  }, [data?.id, data?.variant, product]);

  if (!data?.id) {
    return null;
  } else {
    return Summary;
  }
};
