import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

export const InfoIconBenefit = (props) => {
  const getDates = () => {
    let info = "";
    for (let page of props.pages) {
      if (page.InsurancePlans && page.InsurancePlans.length > 0) {
        let start = moment(page.InsurancePlans[0].EffectiveDate)
          .utc()
          .format("MM/DD/YYYY");
        let end = moment(page.InsurancePlans[0].DeductibleResetDate)
          .utc()
          .format("MM/DD/YYYY");
        info = `Timeframe for this question begins the day you are eligible for benefits but not before ${start}. Ending on ${end}.`;
      }
    }

    if (props.moreInfo) {
      info = `${info} ${props.moreInfo}`;
    }
    if (!info) {
      info = "";
    }
    return info;
  };
  return (
    <Tooltip
      PopperProps={{ style: { zIndex: 234234 } }}
      title={getDates()}
      enterTouchDelay={10}
    >
      <IconButton aria-label="Additional information">
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
};
