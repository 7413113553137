import React, { useMemo, useState } from "react";
import { CaretDown, CaretUp } from "phosphor-react";
import IconButton from "@material-ui/core/IconButton";

import { motion } from "framer-motion";
import { css } from "emotion";
import {
  benefitContainer,
  itemContainer,
  listContainer,
  questionContainer,
  smallScreen,
  titleStyle,
} from "../additional-benefits/product-components/shared-styles";
import { Collapse, Typography } from "@material-ui/core";
import { CarrierLogo } from "../../brite-viewer/components/benefits/carrier-logo";
import { posthogAttrs } from "../../../posthog-constants";

const programs = {
  telemedicine: "Telemedicine",
  wellness_differential: "Wellness Differential",
  disease_management_program: "Disease Management",
  specialty_pharmacy_program: "Specialty Pharmacy",
  health_reimbursement_arrangement: "Health Reimbursement Arrangement",
  custom: "Custom",
};

const descriptions = {
  telemedicine:
    "Speak with a licensed practitioner without going into the doctors office.",
  wellness_differential: "Receive incentives for staying healthy.",
  disease_management_program: "Get support managing your chronic disease.",
  specialty_pharmacy_program:
    "Specialty medications & support for people with complex conditions.",
  health_reimbursement_arrangement:
    "Reimbursements from your employer to help pay for medical expenses.",
  custom: "",
};

// filter out programs that are not supported by the IncludedProgram component
export const filterSupportedPrograms = (pp) => {
  if (!pp) {
    return [];
  }
  return pp.filter((program) => !!programs[program.ProgramType]);
};

export const IncludedProgram = (props) => {
  const [learnMoreOpen, setLearnMoreOpen] = useState(false);

  const programName =
    props?.benefit?.RawData?.Details?.Name ||
    programs[props.benefit.ProgramType] ||
    "";
  const description = descriptions[props.benefit.ProgramType] || "";

  const logoUrl = (props.benefit && props.benefit.LogoURL) || "";

  const carrierName = props.benefit.Carrier || props.benefit.CarrierName || "";

  const titleDescriptions = useMemo(() => {
    if (props.benefit && props.benefit.TitleDescriptions) {
      return props.benefit.TitleDescriptions.filter(
        (item) => !item.Autogenerated && !!item.Title && !!item.Description
      );
    }
    return [];
  }, [props?.benefit?.ID]);

  return (
    programName && (
      <motion.div
        key={"telemedicine"}
        initial="pageInitial"
        animate="pageAnimate"
        exit="pageExit"
        variants={{
          pageInitial: {
            x: 400,
            opacity: 0,
          },
          pageAnimate: {
            x: 0,
            opacity: 1,
          },
          pageExit: {
            x: -400,
            opacity: 0,
          },
        }}
        className={css`
          ${benefitContainer} border: none;
          margin: 0;
          padding: 16px 32px;
        `}
      >
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            ${smallScreen} {
              margin-top: 8px;
            }
          `}
        >
          <div
            className={css`
              margin-right: 16px;
              flex-grow: 1;
            `}
          >
            <div>
              <div
                className={css`
                  display: flex;
                  .title {
                    ${titleStyle}
                    flex-grow: 0;
                  }
                `}
              >
                <Typography className="title">{programName}</Typography>
                {!logoUrl && !!carrierName && (
                  <Typography style={{ fontSize: "12px", margin: "4px 8px" }}>
                    through <strong>{carrierName}</strong>
                  </Typography>
                )}
              </div>
              <div className={questionContainer}>
                <Typography className="question">{description}</Typography>
              </div>
            </div>
          </div>
          <CarrierLogo
            carrierID={props?.benefit?.CarrierID}
            planCarrierName={carrierName}
            maxWidth="100px"
            maxHeight="80px"
            position="center"
          />
        </div>

        <div
          className={css`
            display: flex;
            align-items: start;
            @media only screen and (min-width: 600px) {
              display: none;
            }
            .question {
              margin: 0;
              padding: 0;
              padding-top: 8px;
              flex-grow: 1;
              text-align: left;
              white-space: normal;
              overflow-wrap: break-word;
              font-size: 14px;
            }
          `}
        >
          <div className="question">
            Speak with a licensed practitioner without going into the doctors
            office
          </div>
        </div>

        <Collapse
          in={learnMoreOpen}
          timeout="auto"
          unmountOnExit
          style={{ padding: "16px 0" }}
        >
          {!!titleDescriptions.length && (
            <div className={listContainer}>
              {titleDescriptions.map((item) => (
                <div
                  className={itemContainer}
                  key={item.Title + item.Description}
                >
                  <Typography>{item.Title}</Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {item.Description}
                  </Typography>
                </div>
              ))}
            </div>
          )}

          <Typography color="secondary">
            *There are other aspects of this benefit to consider, including
            pricing, coverage limitations, exclusions, reductions and other ways
            this benefit will or will not pay. Please refer to your plan
            documents for full coverage.
          </Typography>
        </Collapse>

        <div
          className={css`
            display: flex;
            justify-content: center;
            margin-bottom: -20px;
          `}
        >
          <IconButton
            data-testid={`learn-more-${props.benefit.ProgramType}`}
            aria-label="Learn more"
            onClick={() => setLearnMoreOpen(!learnMoreOpen)}
            data-ph-capture-attribute-source={
              posthogAttrs?.types?.RECOMMENDATIONS
            }
            data-ph-capture-attribute-action={posthogAttrs?.actions?.VIEW_MORE}
            data-ph-capture-attribute-product-id={props?.benefit?.ID}
            data-ph-capture-attribute-product-type={
              "included-program:" + props?.benefit?.Type
            }
          >
            {!learnMoreOpen ? <CaretDown size={24} /> : <CaretUp size={24} />}
          </IconButton>
        </div>
      </motion.div>
    )
  );
};
