import React, { useState, useEffect, useMemo, useRef } from "react";

import { motion } from "framer-motion";
import { PlanViewer } from "./PlanViewer";
import { CustomAxios } from "../../../axios/axios";

import { css } from "emotion";
import { HSAPlanViewer } from "./HSAPlanViewer";
import { FSAPlanViewer } from "./FSAPlanViewer";
import { DependantFSAPlanViewer } from "./DependantFSAPlanViewer";
import { LimitedFSAPlanViewer } from "./LimitedPurposeFSAPlanViewer";
import { AdditionalBenefitsList } from "../additional-benefits/AdditionalBenefitsList";
import { IncludedProgram, filterSupportedPrograms } from "./included-program";
import { CustomProductsList } from "../CustomProductsList";

import { BRITE_RECS, BRITE_USER_RECS } from "../constants";
import { Typography } from "@material-ui/core";
import { benefitListContainer } from "../additional-benefits/product-components/shared-styles";
import { SpecialHealthcareRecs } from "../additional-benefits/SpecialHealthcareRecs";
import { cloneDeep } from "lodash";
import { OtherPlansModal } from "./OtherPlansModal";
import { useStore } from "../../store-provider/use-store";
import uuid from "uuid";
import { ShareRec } from "../../share-modal/share-rec";
import { GraduationCap, ArrowsClockwise } from "phosphor-react";
import { ProductComparison } from "../../brite-viewer/components/benefits/product-comparisons";
import { posthogAttrs } from "../../../posthog-constants";
import { Button } from "../../../shared-components";

const coreTypes = [
  "dental_buyup",
  "vision_buyup",
  "dental_ortho_buyup",
  "supplimental_life",
  "short_term_disability",
  "long_term_disability",
  "basic_life_v2",
  "supplemental_life_v2",
];

const additionalTypes = [
  "critical_illness",
  "accident_plan",
  "hospital_indemnity",
  "cancer_buyup",
];

export const RecommendationList = (props) => {
  const {
    data: { productLayouts },
    setStore,
  } = useStore();

  const [selectedMedicalRec, setSelectedMedicalRec] = useState({
    Plan: { ID: "none" },
  });

  useEffect(() => {
    setStore("RelevantTier", selectedMedicalRec?.RelevantTier);
  }, [selectedMedicalRec?.RelevantTier]);

  const [showAllPlans, setShowAllPlans] = useState(false);
  const [showComparisons, setShowComparisons] = useState(false);
  const [medicalType, setMedicalType] = useState("default");
  const [showShare, setShowShare] = useState(false);
  const [shareUrl, setShareUrl] = useState(
    window.location.origin + window.location.pathname + `?rec=${props.recId}`
  );

  const [newMedicalPlanSelected, setNewMedicalPlanSelected] = useState(false);
  useEffect(() => {
    props.setOnRecommendationListPage(true);
    return () => {
      props.setOnRecommendationListPage(false);
    };
  }, []);

  const [recPage, setRecPage] = useState(1);

  const {
    data: { benefitsPackage = [] },
  } = useStore();

  const plans = useMemo(() => {
    return (
      benefitsPackage?.filter(
        ({ Type, MultiNetworkCategory }) =>
          Type === "insurance_plan" &&
          (MultiNetworkCategory === "default" ||
            MultiNetworkCategory === "core")
      ) || []
    );
  }, [benefitsPackage?.length]);

  useEffect(() => {
    if (
      props.healthCareRecommendation &&
      props.healthCareRecommendation.length > 0
    ) {
      setSelectedMedicalRec(props.healthCareRecommendation[0]);
    }
  }, [JSON.stringify(props.healthCareRecommendation)]);

  const getProductByType = (products, productType) => {
    for (let product of products) {
      if (product.Product) {
        if (product.Product.Type === productType) {
          product.Product.Business = product.Provider;
          return product;
        }
      }
    }

    return null;
  };

  const showHealthcareGovCard = () => {
    let product = getProductByType(props.allCoreProducts, "healthcaregov");
    return product ? product.Suggested : false;
  };

  const showReferenceBasedPricing = () => {
    let product = getProductByType(
      props.allCoreProducts,
      "reference_based_pricing"
    );

    return product ? product.Suggested : false;
  };

  const showMedicare = () => {
    let product = getProductByType(
      props.allCoreProducts,
      "medicare_recommendation"
    );

    return product ? product.Suggested : false;
  };

  const getProductsGrouped = (products) => {
    const prodsByType = products.reduce(
      (prev, item) => ({
        ...prev,
        [item.Product.Type]: [...(prev[item.Product.Type] || []), item],
      }),
      {}
    );

    return prodsByType;
  };

  const showSpecialMedicalPlan = () => {
    if (
      showMedicare() ||
      showReferenceBasedPricing() ||
      showHealthcareGovCard()
    ) {
      if (medicalType === "default" || medicalType === "special") {
        return true;
      }
    }
    return false;
  };

  const moveToFront = (data, matchingId) => {
    //find the index of the element in the array
    const index = data.findIndex(({ Plan }) => Plan.ID === matchingId);
    if (index !== -1) {
      //if the matching element is found,
      const updatedData = [...data];
      //then remove that element and use `unshift`
      updatedData.unshift(...updatedData.splice(index, 1));
      return updatedData;
    }
    //if the matching element is not found, then return the same array
    return data;
  };

  const getSelectedBenefitsProps = () => {
    let clonedProps = cloneDeep(props);

    clonedProps.healthCareRecommendation = moveToFront(
      clonedProps.healthCareRecommendation,
      selectedMedicalRec.Plan.ID
    );

    if (
      newMedicalPlanSelected &&
      (showMedicare() || showReferenceBasedPricing() || showHealthcareGovCard())
    ) {
      if (showHealthcareGovCard()) {
        for (let product of clonedProps.allCoreProducts) {
          if (product.Product) {
            if (product.Product.Type === "healthcaregov") {
              product.Suggested = false;
            }
          }
        }
      }

      if (showReferenceBasedPricing()) {
        for (let product of clonedProps.allCoreProducts) {
          if (product.Product) {
            if (product.Product.Type === "reference_based_pricing") {
              product.Suggested = false;
            }
          }
        }
      }

      if (showMedicare()) {
        for (let product of clonedProps.allCoreProducts) {
          if (product.Product) {
            if (product.Product.Type === "medicare_recommendation") {
              product.Suggested = false;
            }
          }
        }
      }
    }
    return clonedProps;
  };

  const isInit = useRef(true);
  const isInitClose = useRef(true);

  const closeRec = () => {
    let updatedAt = new Date().toISOString();

    let path = window.location.pathname;

    let paths = path.split("/");
    let vanityPathIndex = 1;

    for (let p of paths) {
      if (p === "survey") {
        vanityPathIndex = 2;
      }
    }

    try {
      window.localStorage.setItem(
        BRITE_RECS + paths[vanityPathIndex],
        JSON.stringify({
          ...getSelectedBenefitsProps(),
          updatedAt,
        })
      );
    } catch (e) {
      window.localStorage.clear();
    }

    try {
      window.localStorage.setItem(
        BRITE_USER_RECS + paths[vanityPathIndex],
        JSON.stringify({
          ...getSelectedBenefitsProps(),
          updatedAt,
        })
      );
    } catch (e) {
      window.localStorage.clear();
    }
    props.setOriginalRecs({
      ...getSelectedBenefitsProps(),
      updatedAt,
    });
    props.setUserSelectedRecs({
      ...getSelectedBenefitsProps(),
      updatedAt,
    });

    props.setDecisionToolOpen(false);

    if (props.oldPageIndex >= 0) {
      props.setCurrentPageIndex(props.oldPageIndex);
    }
  };

  useEffect(() => {
    if (isInitClose.current) {
      isInitClose.current = false;

      return;
    }

    closeRec();
  }, [props.closeClicked]);

  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;

      return;
    }

    if (!props.recId) {
      saveRecToBackend();
    } else {
      setShowShare(true);
    }
  }, [props.shareClicked, props.recId]);

  useEffect(() => {
    setShareUrl(
      window.location.origin + window.location.pathname + `?rec=${props.recId}`
    );
  }, [props.recId]);

  const saveRecToBackend = async () => {
    try {
      let id = uuid.v4();
      let updatedAt = new Date().toISOString();

      const employeeElections = `/public/v1/bp/employee_elections`;
      const response = await CustomAxios.post(
        employeeElections,
        {
          id,
          user_id: "abc123fingerprint",
          guide_id: "bde70e19-e1b8-497d-ba74-92a725b9fbde",
          plan_elections: [
            {
              ...getSelectedBenefitsProps(),
              updatedAt,
            },
          ],
          product_elections: [],
        },
        {
          headers: {
            BusinessID: "9a27af1d-b40f-4626-a693-e5fc869d8d5c",
            "Content-Profile": "brite_public",
          },
        }
      );
      setShareUrl(
        window.location.origin + window.location.pathname + `?rec=${id}`
      );
      props.setRecId(id);
    } catch (err) {
      props.setRecId("");
      console.error(err);
    }
  };

  const allSpendingAccounts = useMemo(() => {
    const spendingAccounts = props?.spendingAccounts || [];
    const medicalSpendingAccounts =
      selectedMedicalRec?.PlanProductSuggestions || [];
    return [...spendingAccounts, ...medicalSpendingAccounts];
  }, [selectedMedicalRec?.Plan?.ID]);

  return (
    <>
      <motion.div
        className={css`
          font-family: Roboto, Montserrat, sans-serif !important;
          text-align: left;
        `}
        key={"rec-list_question"}
        initial="pageInitial"
        animate="pageAnimate"
        exit="pageExit"
        variants={{
          pageInitial: {
            x: 400,
            opacity: 0,
          },
          pageAnimate: {
            x: 0,
            opacity: 1,
          },
          pageExit: {
            x: -400,
            opacity: 0,
          },
        }}
      >
        <div
          className={css`
            font-size: 34px;
            font-weight: bold;
            margin: 16px;
            line-height: 44px;
            color: #25282d;
            @media only screen and (max-width: 595px) {
              font-size: 24px;
              line-height: 30px;
            }
          `}
        >
          Your Recommendations
        </div>

        <div
          className={css`
            font-size: 16px;
            line-height: 160%;
            margin: 16px;
            margin-top: -8px;
            letter-spacing: 0.15px;

            color: #66737f;
            @media only screen and (max-width: 595px) {
              font-size: 12px;
            }
          `}
        >
          Based on your responses, the benefits shown below best fit your
          personal situation. Review the list and optionally add or remove
          benefits. Share the list and refer to it during enrollment.
        </div>
        {props.healthCareRecommendation &&
          props.healthCareRecommendation.length > 0 && (
            <div
              className={css`
                margin-top: 40px;
              `}
            >
              {!props.hideMedicalPlans &&
                (props.healthCareRecommendation ||
                  showSpecialMedicalPlan()) && (
                  <div
                    className={css`
                      font-size: 24px;
                      font-weight: bold;
                      margin-left: 16px;
                      margin-top: 18px;
                      padding-bottom: 6px;

                      @media only screen and (max-width: 600px) {
                        text-align: center;
                        margin: 16px;
                        font-size: 18px;
                      }
                    `}
                  >
                    Medical Plan
                  </div>
                )}

              {!props.hideMedicalPlans &&
                ((props.healthCareRecommendation &&
                  props.healthCareRecommendation.length > 0) ||
                  showSpecialMedicalPlan()) && (
                  <>
                    {showSpecialMedicalPlan() && (
                      <>
                        {showHealthcareGovCard() && (
                          <div
                            className={css`
                              margin-top: 16px;
                            `}
                          >
                            <SpecialHealthcareRecs
                              header={
                                getProductByType(
                                  props.allCoreProducts,
                                  "healthcaregov"
                                ).Product.ProductName || "Individual Plan"
                              }
                              benefit={getProductByType(
                                props.allCoreProducts,
                                "healthcaregov"
                              )}
                              designStyles={props.designStyles}
                            />
                            <OtherPlansModal
                              showCheckbox={false}
                              showMonthlyCosts={props.showMonthlyCosts}
                              answers={props.answers}
                              plan={selectedMedicalRec}
                              isSelected={true}
                              setMedicalType={setMedicalType}
                              setSelectedMedicalRec={(rec) => {
                                setSelectedMedicalRec(rec);
                                setNewMedicalPlanSelected(true);
                              }}
                              setRecPage={setRecPage}
                              allMedicalPlans={props.healthCareRecommendation}
                              showAllPlans={showAllPlans}
                              setShowAllPlans={setShowAllPlans}
                              designStyles={props.designStyles}
                            />
                          </div>
                        )}
                        {showMedicare() && (
                          <div
                            className={css`
                              margin-top: 16px;
                            `}
                          >
                            <SpecialHealthcareRecs
                              header={
                                getProductByType(
                                  props.allCoreProducts,
                                  "medicare_recommendation"
                                ).Product.ProductName || "Medicare"
                              }
                              benefit={getProductByType(
                                props.allCoreProducts,
                                "medicare_recommendation"
                              )}
                              designStyles={props.designStyles}
                            />
                            <OtherPlansModal
                              showCheckbox={false}
                              showMonthlyCosts={props.showMonthlyCosts}
                              answers={props.answers}
                              plan={selectedMedicalRec}
                              isSelected={true}
                              setSelectedMedicalRec={(rec) => {
                                setSelectedMedicalRec(rec);
                                setNewMedicalPlanSelected(true);
                              }}
                              setMedicalType={setMedicalType}
                              setRecPage={setRecPage}
                              allMedicalPlans={props.healthCareRecommendation}
                              showAllPlans={showAllPlans}
                              setShowAllPlans={setShowAllPlans}
                              designStyles={props.designStyles}
                            />
                          </div>
                        )}

                        {showReferenceBasedPricing() && (
                          <div
                            className={css`
                              margin-top: 16px;
                            `}
                          >
                            <SpecialHealthcareRecs
                              header={
                                getProductByType(
                                  props.allCoreProducts,
                                  "reference_based_pricing"
                                ).Product.ProductName ||
                                "Reference Based Pricing. A new type of Health Plan"
                              }
                              benefit={getProductByType(
                                props.allCoreProducts,
                                "reference_based_pricing"
                              )}
                              designStyles={props.designStyles}
                            />
                            <OtherPlansModal
                              showCheckbox={false}
                              showMonthlyCosts={props.showMonthlyCosts}
                              answers={props.answers}
                              plan={selectedMedicalRec}
                              isSelected={true}
                              setSelectedMedicalRec={(rec) => {
                                setSelectedMedicalRec(rec);
                                setNewMedicalPlanSelected(true);
                              }}
                              setMedicalType={setMedicalType}
                              setRecPage={setRecPage}
                              allMedicalPlans={props.healthCareRecommendation}
                              showAllPlans={showAllPlans}
                              setShowAllPlans={setShowAllPlans}
                              designStyles={props.designStyles}
                            />
                          </div>
                        )}
                      </>
                    )}

                    {!showSpecialMedicalPlan() && (
                      <>
                        <>
                          <div
                            className={css`
                              border-radius: 16px;
                              margin: 16px;
                              text-align: left;
                              background: #fff;
                              border: 4px solid
                                ${props.designStyles?.Theme?.Body?.ButtonColor
                                  ? props.designStyles?.Theme?.Body?.ButtonColor
                                  : "#25282D"};
                            `}
                          >
                            <PlanViewer
                              showCheckbox={false}
                              showMonthlyCosts={props.showMonthlyCosts}
                              answers={props.answers}
                              plan={selectedMedicalRec}
                              productLayouts={productLayouts}
                              isSelected={true}
                              setSelectedMedicalRec={(rec) => {
                                setSelectedMedicalRec(rec);
                                setNewMedicalPlanSelected(true);
                              }}
                              setRecPage={setRecPage}
                              allMedicalPlans={props.healthCareRecommendation}
                              showAllPlans={showAllPlans}
                              setMedicalType={setMedicalType}
                              setShowAllPlans={setShowAllPlans}
                              businessId={props.page.BusinessID}
                              displaySettings={props.displaySettings}
                              designStyles={props.designStyles}
                            />

                            {selectedMedicalRec &&
                              selectedMedicalRec.Plan &&
                              selectedMedicalRec.Plan.includedPrograms &&
                              !!filterSupportedPrograms(
                                selectedMedicalRec.Plan.includedPrograms
                              ).length && (
                                <div
                                  className={css`
                                    padding-bottom: 12px;
                                    padding-top: 12px;
                                    border-top: 2px solid #f4f7fa;
                                  `}
                                >
                                  <div
                                    className={css`
                                      font-weight: bold;
                                      font-size: 20px;
                                      text-align: left;
                                      color: #25282d;
                                      margin: 24px 32px;
                                      margin-top: 12px;
                                      @media only screen and (max-width: 600px) {
                                        margin: 24px 12px;
                                        margin-top: 12px;
                                        font-size: 16px;
                                      }
                                    `}
                                  >
                                    Included Programs
                                  </div>
                                  <div
                                    className={css`
                                      border-radius: 16px;
                                      border: 1px solid #d1dae3;
                                      margin: 24px 32px;

                                      background-color: white;
                                      margin-top: 0px;

                                      @media only screen and (max-width: 600px) {
                                        margin: 24px 12px;
                                      }
                                    `}
                                  >
                                    {filterSupportedPrograms(
                                      selectedMedicalRec.Plan.includedPrograms
                                    ).map((item) => (
                                      <IncludedProgram benefit={item} />
                                    ))}
                                  </div>
                                </div>
                              )}
                          </div>
                        </>
                      </>
                    )}

                    {props.healthCareRecommendation &&
                      props.healthCareRecommendation.length > 1 && (
                        <div
                          className={css`
                            display: flex;
                            justify-content: start;
                            padding-bottom: 20px;
                            padding-top: 8px;
                            margin-left: 16px;
                            @media only screen and (max-width: 600px) {
                              justify-content: center;
                            }
                          `}
                        >
                          <Button
                            secondary
                            onClick={() => {
                              setShowAllPlans(true);
                            }}
                            data-ph-capture-attribute-source={
                              posthogAttrs?.types?.RECOMMENDATIONS
                            }
                            data-ph-capture-attribute-action={
                              posthogAttrs?.actions?.VIEW_MORE
                            }
                          >
                            View Other Plans
                          </Button>
                          {props?.page?.Content?.decisionTool
                            ?.displayComparisonModal && (
                            <Button
                              onClick={() => {
                                setShowComparisons(true);
                              }}
                              data-ph-capture-attribute-source={
                                posthogAttrs?.types?.RECOMMENDATIONS
                              }
                              data-ph-capture-attribute-action={
                                posthogAttrs?.actions?.SHOW_COMPARISONS
                              }
                              style={{
                                marginLeft: "8px",
                                backgroundColor: `${
                                  props.designStyles?.Theme?.Body?.ButtonColor
                                    ? props.designStyles?.Theme?.Body
                                        ?.ButtonColor
                                    : "#25282D"
                                }`,
                              }}
                            >
                              Compare Plans
                            </Button>
                          )}
                        </div>
                      )}
                  </>
                )}
            </div>
          )}

        {!props.hideMedicalPlans && allSpendingAccounts?.length
          ? !showSpecialMedicalPlan() && (
              // css`text-align: left; margin: 0 16px;`
              // style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '16px', marginTop: '48px' }}
              <div className={benefitListContainer}>
                <Typography className="section-title">
                  Eligible Spending Accounts
                </Typography>
                {allSpendingAccounts.map((s, idx) => {
                  if (s.Product.Type === "fsa_buyup") {
                    return (
                      <FSAPlanViewer
                        key={"fsa_buyup" + idx}
                        benefit={s.Product}
                        carrier={s.Provider}
                        designStyles={props.designStyles}
                      />
                    );
                  } else if (s.Product.Type === "dependant_care_fsa_buyup") {
                    return (
                      <DependantFSAPlanViewer
                        key={"dependant_care_fsa_buyup" + idx}
                        benefit={s.Product}
                        carrier={s.Provider}
                        designStyles={props.designStyles}
                      />
                    );
                  } else if (s.Product.Type === "hsa_buyup") {
                    return (
                      <HSAPlanViewer
                        key={"hsa_buyup" + idx}
                        benefit={s.Product}
                        carrier={s.Provider}
                        answers={props.answers}
                        plan={selectedMedicalRec}
                        designStyles={props.designStyles}
                      />
                    );
                  } else if (s.Product.Type === "limited_fsa_buyup") {
                    return (
                      <LimitedFSAPlanViewer
                        key={"limited_fsa_buyup" + idx}
                        benefit={s.Product}
                        carrier={s.Provider}
                        designStyles={props.designStyles}
                      />
                    );
                  }
                })}
              </div>
            )
          : null}

        <AdditionalBenefitsList
          title="Valuable Core Benefits"
          productTypes={coreTypes}
          productData={getProductsGrouped(props.allCoreProducts)}
          answers={props.answers}
          displaySettings={props.displaySettings}
          setSelected={props.setSelectedBenefits}
          selected={props.selectedBenefits}
          designStyles={props.designStyles}
        />

        <AdditionalBenefitsList
          title="Supplemental Health Benefits "
          productTypes={additionalTypes}
          productData={getProductsGrouped(props.allAdditionalProducts)}
          answers={props.answers}
          setSelected={props.setSelectedBenefits}
          selected={props.selectedBenefits}
          designStyles={props.designStyles}
        />

        <CustomProductsList
          customQuestionsAnswers={props.customQuestionsAnswers}
          customQuestions={props.customQuestions}
          allCustomProducts={props.allCustomProducts}
          recommendedCustomProducts={props.recommendedCustomProducts}
          setSelected={props.setSelectedBenefits}
          selected={props.selectedBenefits}
          designStyles={props.designStyles}
        />

        <div
          className={css`
            margin: 24px;
            padding-top: 48px;
          `}
        >
          <div
            className={css`
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;
              /* identical to box height, or 133% */

              color: #25282d;
              align-self: flex-start;

              @media only screen and (max-width: 600px) {
                text-align: center;
                margin: 0 16px;
                font-size: 18px;
                align-self: center;
              }
            `}
          >
            Use your recommendations to enroll now. Not ready yet?
          </div>
          <div
            className={css`
              border: 2px solid #25282d;
              border-radius: 8px;
              display: flex;
              gap: 24px;
              padding: 24px;
              background: white;
              margin-top: 24px;
              margin-bottom: 24px;
              flex-wrap: wrap;
            `}
          >
            <div>
              <GraduationCap size={40} />
            </div>
            <div>
              <div
                className={css`
                  font-family: "Roboto";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 24px;

                  /* identical to box height, or 133% */

                  color: #25282d;
                `}
              >
                Continue exploring the details of your company benefits
              </div>
              <div
                className={css`
                  font-family: "Roboto";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  /* or 150% */

                  color: #66737f;
                  margin-top: 8px;
                  margin-bottom: 16px;
                `}
              >
                Spend more time looking through our benefit guide.
              </div>
              <Button
                style={{
                  margin: "0 4px",

                  border: "1px solid #D1DAE3",
                }}
                onClick={() => {
                  closeRec();
                }}
                color="primary"
                variant="outlined"
                size="large"
                data-ph-capture-attribute-source="back-to-guide"
              >
                Back to Guide
              </Button>
            </div>
          </div>

          <div
            className={css`
              border: 2px solid #25282d;
              border-radius: 8px;
              display: flex;
              gap: 24px;
              padding: 24px;
              background: white;
              margin-top: 24px;
              margin-bottom: 24px;
              flex-wrap: wrap;
            `}
          >
            <div>
              <ArrowsClockwise size={40} />
            </div>
            <div>
              <div
                className={css`
                  font-family: "Roboto";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 24px;

                  /* identical to box height, or 133% */

                  color: #25282d;
                `}
              >
                Run a different scenario
              </div>
              <div
                className={css`
                  font-family: "Roboto";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  /* or 150% */

                  color: #66737f;
                  margin-top: 8px;
                  margin-bottom: 16px;
                `}
              >
                Get a new recommendation with a different scenario.
              </div>
              <div className={css`
                display: flex;
                `}>
              <Button
                secondary
                style={{
                  margin: "0 4px",

                  border: "1px solid #D1DAE3",
                }}
                onClick={() => {props.restart()}}
                color="primary"
                variant="outlined"
                size="large"
              >
                Restart
              </Button>
              <Button
                style={{
                  margin: "0 4px",

                  border: "1px solid #D1DAE3",
                }}
                onClick={() => {props.setShowRecommendationResults(false)}}
                color="primary"
                variant="outlined"
                size="large"
              >
              Edit Responses
              </Button>
              </div>
            </div>
          </div>
        </div>

        {props?.page?.Content?.decisionTool?.displayComparisonModal && (
          <ProductComparison
            display={showComparisons}
            onClose={() => setShowComparisons(false)}
            productType="insurance_plan"
          />
        )}
      </motion.div>

      {showShare && (
        <ShareRec
          setShowShare={setShowShare}
          shareData={{
            title: "Benefit Package Recommendations",
            text:
              "Based on your responses, the benefits shown below best fit your personal situation. ",
            url: shareUrl,
          }}
          designStyles={props.designStyles}
        />
      )}
    </>
  );
};
