import { css } from "emotion";
import { SummaryField } from "../../components/brite-viewer/components/benefits/summary-field";
import { Div } from "../div";
import { flex } from "../shared-styles";

const basicFieldContainer = css`
  ${flex("space-between")}
  padding: 8px 16px;
  .rec {
    width: 100%;
  }
  .cost {
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 64px);
  }
`;

export const RecommendationFieldList = ({ product, fields, props = {} }) => {
  return (
    <>
      {fields?.map((item) => {
        const { config = {}, ...field } = item;

        if (config?.hideField) {
          return null;
        }

        if (config?.components?.length) {
          return config?.components?.map((Component) => (
            <Div css={basicFieldContainer} className="alternating-item">
              <Component field={item} props={props} />
            </Div>
          ));
        } else {
          return (
            <Div className="alternating-item" onClick={() => console.log(item)}>
              <SummaryField product={product} field={field} config={config} />
            </Div>
          );
        }
      })}
    </>
  );
};
