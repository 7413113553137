import React, { useState, useEffect, useMemo, useCallback } from "react";
import { css } from "emotion";
import { ThemeProvider } from "@material-ui/core/styles";
import posthog from "posthog-js";
import { isMobile } from "react-device-detect";
import { Error } from "./error";
import { Loader } from "./loader";
import { MobileView } from "./mobile-view";
import { PasscodePage } from "./Passcode";
import { useBenefitsHook } from "../api-hooks/use-benefits-hook";
import { CustomAxios } from "../axios/axios";
import { useKeyboard } from "../lib/use-keyboard";
import { theme } from "../mui-theme";

export const Viewer = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 900);
  const [restartRecommendation, setRestartRecommendation] = useState(false);
  const [courseTimeSubmitted, setCourseTimeSubmitted] = useState(false);
  const [courseStartTime, setCourseStartTime] = useState(new Date());
  const [designStyles, setDesignStyles] = useState(null);

  const [headerHeight, setHeaderHeight] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const [topOfContent, setTopOfContent] = useState(0);

  const updateContentPosition = useCallback((height, top) => {
    setContentHeight(height);
    setTopOfContent(top);
  }, []);

  const {
    pages,
    loading,
    error,
    isAcaPlan,
    isIchraPlan,
    promptOptions,
    customQuestions,
    showPasscodePage,
    dynamicQuestions,
    setShowPasscodePage,
    setLoading,
    archived,
    displaySettings,
  } = useBenefitsHook();

  useEffect(() => {
    const getDesignStyles = async () => {
      const { CourseID = "" } = pages?.[0] || {};
      try {
        const url = `/public/v1/course/${CourseID}/theme`;
        const { data } = await CustomAxios.get(url);
        setDesignStyles(data);
      } catch (err) {
        console.warn(err);
      }
    };

    if (pages && pages.length > 0) {
      getDesignStyles();
    }
  }, [pages.length]);

  const [onRecommendationListPage, setOnRecommendationListPage] = useState(
    false
  );

  const [showRecActionModal, setShowRecActionModal] = useState(false);

  const [time, setTime] = useState(new Date());
  const [lastViewedPageId, setLastViewedPageId] = useState(null);

  const [currentPage, setCurrentPage] = useState({ ID: "" });

  useKeyboard({
    "meta+shift+KeyX": () => {
      const adminDomain = window.location.origin?.includes(
        "employee-view-staging"
      )
        ? "admin-frontend-staging-tqtafkpv4q-uw.a.run.app"
        : "app.be-brite.com";
      window.open(
        `https://${adminDomain}/${currentPage?.BusinessID}/courses/${currentPage?.CourseID}?pageId=${currentPage?.ID}`,
        "_blank"
      );
    },
    "meta+shift+KeyD": () =>
      window.open(
        `localhost:3000/${currentPage?.BusinessID}/courses/${currentPage?.CourseID}?pageId=${currentPage?.ID}`,
        "_blank"
      ),
  });

  const [currentPageIndex, _setCurrentPageIndex] = useState(() => {
    let currentUrl = window.location.pathname;
    if (currentUrl) {
      let splitCurrentUrl = currentUrl.split("/");
      let maxlength = 3;

      for (let path of splitCurrentUrl) {
        if (path === "survey") {
          maxlength = 4;
        }
      }
      if (splitCurrentUrl.length < maxlength) {
        return 0;
      } else {
        let startingPage = Number(splitCurrentUrl[splitCurrentUrl.length - 1]);
        if (Number.isInteger(startingPage)) {
          return Math.max(0, startingPage - 1);
        } else {
          return 0;
        }
      }
    }
    return 0;
  });

  useEffect(() => {
    // checking pages.length > 0 first so that we don't redirect when someone navigates directly to a page in the middle of the course (since pages.length is still 0 when this viewer first loads).
    if (pages.length > 0 && currentPageIndex > pages.length) {
      // reset back to the beginning if they have surpassed the available pages in this course.
      _setCurrentPageIndex(0);
    }
  }, [pages.length, currentPageIndex]);

  const courseTypeIncludesElections =
    pages &&
    pages[0] &&
    (pages[0].CourseType === "openenrollment" ||
      pages[0].CourseType === "newhire");

  const [associatedCarrierIDs, setAssociatedCarrierIDs] = useState([]);

  const handlePageHTMLLoaded = (data) => {
    if (
      !data ||
      !data.AssociatedCarrierIDs ||
      !data.AssociatedCarrierIDs.length
    ) {
      setAssociatedCarrierIDs([]);
      return;
    }

    setAssociatedCarrierIDs(data.AssociatedCarrierIDs);
  };

  const logPageView = (pageID, nextPageIndex, opt) => {
    if (showPasscodePage) {
      return;
    }
    let nextPageID =
      nextPageIndex !== null && pages[nextPageIndex]
        ? pages[nextPageIndex].ID
        : null;

    const t = new Date();
    let timeSpentOnPage = Math.round((t - time) / 1000);

    let viewportHeight = 0;
    let totalContentHeight = 0;
    let scrollDataCaptured = false;
    let distanceScrolled = 0;
    let distanceNeededToScrollTheEntireContent = 0;

    if (
      pages[currentPageIndex] &&
      pages[currentPageIndex].Type === "brite-editor"
    ) {
      scrollDataCaptured = true;
      let totalAmountNeededToScrollToViewEntireContent =
        contentHeight - containerHeight;

      if (totalAmountNeededToScrollToViewEntireContent < 0) {
        totalAmountNeededToScrollToViewEntireContent = 0;
      }

      distanceNeededToScrollTheEntireContent = totalAmountNeededToScrollToViewEntireContent;
      viewportHeight = containerHeight;
      totalContentHeight = contentHeight;

      distanceScrolled = Math.abs(topOfContent - (headerHeight + 1));
    }

    posthog.capture(
      "BritePageViewed",
      {
        pageID: pageID,
        durationSeconds: timeSpentOnPage,
        previousPageID: lastViewedPageId,
        nextPageID: nextPageID,
        associatedCarrierIDs: associatedCarrierIDs,
        scrollData: {
          scrollDataCaptured,
          viewportHeight,
          totalContentHeight,
          distanceScrolled: distanceScrolled,
          distanceNeededToScrollTheEntireContent,
        },
      },
      opt
    );

    setTime(t);
    setLastViewedPageId(pageID);
  };

  const setCurrentPageIndex = (index) => {
    // if the index isn't changing then don't do anything
    if (index === currentPageIndex) {
      return;
    }

    logPageView(pages[currentPageIndex].ID, index, { transport: "sendbeacon" });
    _setCurrentPageIndex(index);
  };

  useEffect(() => {
    const handler = (e) => {
      if (isMobile) {
        if (e.target.visibilityState === "hidden") {
          logPageView(pages[currentPageIndex].ID, null);
        }
      }
    };

    const handlerDesktop = (e) => {
      if (!isMobile) {
        logPageView(pages[currentPageIndex].ID, null);
      }
    };

    window.addEventListener("visibilitychange", handler);
    window.addEventListener("beforeunload", handlerDesktop);

    return () => {
      window.removeEventListener("visibilitychange", handler);
      window.removeEventListener("beforeunload", handlerDesktop);
    };
  }, [lastViewedPageId, time, currentPageIndex, pages.length, isMobile]);

  useEffect(() => {
    if (
      !courseTimeSubmitted &&
      currentPageIndex !== 0 &&
      pages.length > 0 &&
      !showPasscodePage
    ) {
      if (currentPageIndex === pages.length - 1) {
        let end = new Date();
        let diffMs = end - courseStartTime;
        let diffSeconds = diffMs / 1000;

        // Display confidence to elect survey (as long as they have spent a little time on this course) and it's the right course type.

        setCourseTimeSubmitted(true);

        // they must have been on different tabs or not engaged if they have been viewing the course for that long.
        // don't send analytics to us
        if (diffSeconds < 2000) {
          let courseID = pages[currentPageIndex].courseID;
          if (!courseID) {
            courseID = pages[currentPageIndex].CourseID;
          }
          CustomAxios.post(`/public/v1/${courseID}/analytics/courseTime`, {
            TotalTime: diffSeconds,
            CourseID: courseID,
          });
        }
      }
    }
  }, [
    currentPageIndex,
    pages.length,
    courseStartTime,
    courseTimeSubmitted,
    showPasscodePage,
  ]);

  useEffect(() => {
    if (pages.length && pages.length > 0) {
      if (pages[currentPageIndex]) {
        setCurrentPage(pages[currentPageIndex]);
      }
    }
  }, [pages.length, currentPageIndex]);

  useEffect(() => {
    if (currentPage.ID) {
      posthog.register({
        pageID: currentPage.ID,
      });
      posthog.capture("$pageview");
    }
  }, [currentPage.ID]);

  useEffect(() => {
    if (!loading && pages.length) {
      let currentUrl = window.location.pathname;
      if (currentUrl) {
        let splitCurrentUrl = currentUrl.split("/");
        let maxlength = 3;
        for (let path of splitCurrentUrl) {
          if (path === "survey") {
            maxlength = 4;
          }
        }
        if (splitCurrentUrl.length < maxlength) {
          splitCurrentUrl.push("1");
        } else {
          splitCurrentUrl[maxlength - 1] = Number(currentPageIndex) + 1;
        }
        let newURL = splitCurrentUrl.join("/");
        window.history.pushState({}, `Brite`, newURL + window.location.search);
      }
    }
  }, [currentPageIndex]);

  const initialPathname = useMemo(() => {
    return window.location.pathname;
  }, []);

  useEffect(() => {
    if (!loading && pages?.length) {
      const paths = initialPathname.split("/");
      if (paths.length > 2) {
        let path = paths.at(-1);
        const pageIndex = pages.findIndex(({ ID }) => ID === path);
        if (pageIndex > -1) {
          paths[paths.length - 1] = pageIndex;
          setCurrentPageIndex(pageIndex);
        } else if (/^\d+$/.test(path) && path <= pages.length) {
          setCurrentPageIndex(path - 1);
        } else {
          setCurrentPageIndex(0);
        }
      } else if (window.location.pathname.split("/").length === 2) {
        setCurrentPageIndex(0);
        const newURL = window.location.pathname + "/1";
        window.history.pushState({}, `Brite`, newURL + window.location.search);
      }
    }
  }, [loading, pages.length]);

  useEffect(() => {
    const popState = (e) => {
      let path = window.location.pathname;
      let paths = path.split("/");
      let pageindex = 2;

      for (let p of paths) {
        if (p === "survey") {
          pageindex = 3;
        }
      }
      let pagePath = paths[pageindex];

      if (pagePath === undefined) {
        pagePath = 0;
      } else {
        pagePath -= 1;
      }

      setCurrentPageIndex(pagePath);
    };
    window.addEventListener("resize", updateView);
    window.onpopstate = popState;

    return function cleanup() {
      window.removeEventListener("resize", updateView);
      window.onpopstate = () => {};
    };
  }, []);

  const getLogoUrl = () => {
    if (pages.length > 0) {
      return pages[0].CourseLogoURL;
    }
    return "";
  };

  const updateView = () => {
    setIsDesktop(window.innerWidth > 900);
  };

  const nextPage = (force) => {
    if (currentPageIndex + 1 < pages.length) {
      setCurrentPageIndex(currentPageIndex + 1);
    }
  };

  const isCourseASurvey = () => {
    let path = window.location.pathname;
    let paths = path.split("/");

    for (let p of paths) {
      if (p === "survey") {
        return true;
      }
    }
    return false;
  };

  const disableNextPage = () => {
    return currentPageIndex + 1 >= pages.length;
  };

  const prevPage = (force) => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex(currentPageIndex - 1);
    }
  };

  const restartRecommendationFunction = () => {
    setRestartRecommendation((prev) => {
      return !prev;
    });
  };

  const disablePrevPage = () => {
    return currentPageIndex === 0;
  };
  if (showPasscodePage) {
    return (
      <ThemeProvider theme={theme}>
        <PasscodePage
          setShowPasscodePage={setShowPasscodePage}
          setLoading={setLoading}
        />
      </ThemeProvider>
    );
  }

  if (error) {
    return <Error />;
  }
  if (archived) {
    return (
      <div
        className={css`
          display: flex;
          margin: 40px;
          justify-content: center;

          font-family: "Roboto", sans-serif;
          flex-direction: column;
          text-align: center;
        `}
      >
        <p>This course has been archived and is no longer viewable</p>
      </div>
    );
  }
  if (loading) {
    return <Loader />;
  }

  return (
    <ThemeProvider theme={theme}>
      <>
        <button
          id="skip-to-content"
          className={css`
            position: absolute;
            left: -999px;
            width: 1px;
            height: 1px;
            top: auto;

            &:focus {
              color: black;
              display: inline-block;
              height: auto;
              width: auto;
              position: static;
              margin: auto;
            }
          `}
          onClick={(e) => {
            e.preventDefault();
            const container = document.querySelector("#main-content");

            if (container) {
              container.tabIndex = -1;
              container.focus();
              setTimeout(() => container.removeAttribute("tabindex"), 1000);
            }
          }}
        >
          Skip to Content
        </button>
        <button
          id="skip-to-navigation"
          className={css`
            position: absolute;
            left: -2000px;
            width: 1px;
            height: 1px;
            top: auto;

            &:focus {
              color: black;
              display: inline-block;
              height: auto;
              width: auto;
              position: static;
              margin: auto;
            }
          `}
          onClick={(e) => {
            e.preventDefault();
            const container = document.querySelector("#navigation");

            if (container) {
              container.tabIndex = -1;
              container.focus();
              setTimeout(() => container.removeAttribute("tabindex"), 1000);
            }
          }}
        >
          Skip to Navigation
        </button>
        <button
          id="skip-to-footer"
          className={css`
            position: absolute;
            left: -3000px;
            width: 1px;
            height: 1px;
            top: auto;

            &:focus {
              color: black;
              display: inline-block;
              height: auto;
              width: auto;
              position: static;
              margin: auto;
            }
          `}
          onClick={(e) => {
            e.preventDefault();
            const container = document.querySelector("#footer");

            if (container) {
              container.tabIndex = -1;
              container.focus();
              setTimeout(() => container.removeAttribute("tabindex"), 1000);
            }
          }}
        >
          Skip to Footer
        </button>

        <div
          className={css`
            background: white;
            height: 100%;
            font-family: "Roboto", "Montserrat", sans-serif;
            overflow: hidden;
            .page-nav-next {
              :disabled {
                opacity: 0.5;
              }
            }
          `}
        >
          <MobileView
            isDesktop={isDesktop}
            pages={pages}
            logoUrl={getLogoUrl()}
            currentPageIndex={currentPageIndex}
            setCurrentPageIndex={setCurrentPageIndex}
            currentPage={pages[currentPageIndex]}
            hideNavigation={
              pages[currentPageIndex].HideNavigation || pages?.length === 1
            }
            nextPage={nextPage}
            prevPage={prevPage}
            disableNextPage={disableNextPage}
            disablePrevPage={disablePrevPage}
            restartRecommendation={restartRecommendation}
            restartRecommendationFunction={restartRecommendationFunction}
            isAcaPlan={isAcaPlan}
            isIchraPlan={isIchraPlan}
            promptOptions={promptOptions}
            isSurvey={isCourseASurvey()}
            customQuestions={customQuestions}
            dynamicQuestions={dynamicQuestions}
            setOnRecommendationListPage={setOnRecommendationListPage}
            onRecommendationListPage={onRecommendationListPage}
            showRecActionModal={showRecActionModal}
            setShowRecActionModal={setShowRecActionModal}
            handlePageHTMLLoaded={handlePageHTMLLoaded}
            courseTypeIncludesElections={courseTypeIncludesElections}
            displaySettings={displaySettings}
            designStyles={designStyles}
            setHeaderHeight={setHeaderHeight}
            setContainerHeight={setContainerHeight}
            updateContentPosition={updateContentPosition}
          />
        </div>
      </>
    </ThemeProvider>
  );
};
