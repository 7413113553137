import { useContext } from 'react';
import { StoreContext } from './provider';

export const useStore = (selectorFn = null) => {
  const value = useContext(StoreContext);
  
  const { store, dispatchStore } = value;

  const data = selectorFn === null ? store : selectorFn(store);

  const updateStore = (key, value) =>
    dispatchStore({ type: 'UPDATE', payload: value, key, });

  const setStore = (key, value) =>
    dispatchStore({ type: 'SET', payload: value, key });

  const resetStore = () => {
    dispatchStore({ type: 'RESET' });
  }

  return {
    data,
    updateStore,
    setStore,
    resetStore,
  };
};