import React from "react";

import { css } from "emotion";
import { motion } from "framer-motion";
import Checkbox from "@material-ui/core/Checkbox";

export const DecisionToolDisclaimer = ({
  setDisclaimerError,
  disclaimerError,
  setDisclaimerAccepted,
  disclaimerAccepted,
}) => {
  return (
    <motion.div
      key={"disclaimer_question"}
      initial="pageInitial"
      animate="pageAnimate"
      exit="pageExit"
      variants={{
        pageInitial: {
          x: 400,
          opacity: 0,
        },
        pageAnimate: {
          x: 0,
          opacity: 1,
        },
        pageExit: {
          x: -400,
          opacity: 0,
        },
      }}
      className={css`
        background: #ffffff;

        box-shadow: 0px 2px 8px rgba(37, 40, 45, 0.25);
        border-radius: 8px;
        padding: 24px;
        margin-bottom: 32px;
        text-align: left;
      `}
    >
      <div
        className={css`
          color: #25282d;

          font-feature-settings: "clig" off, "liga" off;
          font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          text-align: left;
        `}
      >
        Terms & Conditions
      </div>

      <div
        className={css`
          text-align: left;
          color: var(--400, #66737f);
          font-feature-settings: "clig" off, "liga" off;
          text-overflow: ellipsis;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          letter-spacing: 0.15px;
          margin-top: 16px;
          height: 140px;
          overflow: auto;
          padding-right: 32px;
        `}
      >
        {" "}
        You assume full responsibility for the correct information relied upon
        by the software and for verifying the results obtained by its use. The
        results provided to you are based off estimates and the software does
        not guarantee that the recommendations will be reflective of your actual
        costs for the year. In no event will the provider of this software
        and/or service be liable to you for any indirect, incidental,
        consequential, special, or exemplary damages arising out of or in
        connection with your use or inability to use the software and/or the
        license. In no event will the provider of this software and/or service
        be liable to you for the breach of any express or implied warranty, or
        otherwise in connection with your use of the software and/or the
        license, even if the provider of this software and/or service has been
        advised of the possibility of such damages. In no event shall the total
        liability of the provider of this software and/or service for any
        damages, direct or indirect, in connection with the software and/or this
        license exceed the license fees paid for your right to use the software
        whether such liability arises from any claim based upon contract,
        warrants, tort, or otherwise.
      </div>

      <div
        className={css`
          display: flex;
          align-items: center;

          margin-top: 24px;
          padding-left: 24px;
          padding-right: 24px;
          padding-top: 12px;
          padding-bottom: 12px;
          border-radius: 8px;
          border: ${disclaimerAccepted
            ? `2px solid #212326`
            : disclaimerError
            ? `2px solid #EB4E3D`
            : `2px solid #9aa7b5`};
          max-width: 310px;
          cursor: pointer;
        `}
        onClick={() => {
          if (!disclaimerAccepted) {
            setDisclaimerError(false);
          }
          setDisclaimerAccepted(!disclaimerAccepted);
        }}
      >
        <Checkbox
          style={{ marginRight: "16px", padding: 0, width: "16px" }}
          checked={disclaimerAccepted}
          color="primary"
          onChange={(e) => {
            setDisclaimerAccepted(e.target.checked);
            if (e.target.checked) {
              setDisclaimerError(false);
            }
          }}
        />
        <div
          className={css`
            color: #25282d;

            font-feature-settings: "clig" off, "liga" off;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 133.333% */
            letter-spacing: 0.15px;
          `}
        >
          I Accept
        </div>
      </div>
      {disclaimerError && (
        <div
          className={css`
            color: var(--Red, #eb4e3d);

            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: 0.15px;
            margin-top: 12px;
          `}
        >
          Must accept Terms to see results
        </div>
      )}
    </motion.div>
  );
};
