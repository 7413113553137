import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      root: {
        borderRadius: 8,
        fontWeight: 700,
      },

      contained: {
        borderRadius: 8,
        boxShadow: "none",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 16,
        backgroundColor: "#66737F",
        fontWeight: 700,
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
      },
    },
  },
  typography: {
    fontFamily: ["Roboto", "Montserrat", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#b1b1b1",
    },
  },
});
