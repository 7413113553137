import { css } from "emotion";
import { Button, Div, Loader, Text } from "../../../../shared-components";
import { flex } from "../../../../shared-components/shared-styles";
import { colors, styles } from "../../../../shared-components/styles";
import { HandWaving } from "phosphor-react";
import { useStore } from "../../../store-provider/use-store";
import { useAcaCost } from "./use-aca-cost";

export const CostACA = (props) => {
  const { product } = props?.data;
  const {
    data: { decisionTool = "" },
    setStore,
  } = useStore();
  const toggleDecisionTool = () =>
    setStore("decisionTool", decisionTool ? "" : "aca");

  const { value, isLoading } = useAcaCost({ productId: product?.ID });

  if (!value?.RelevantTier) {
    return (
      <Div
        css={css`
          background-color: ${colors.gray[100]};
          width: calc(100% - 64px);
          box-sizing: border-box;
          border-radius: 8px;
          margin: 32px;
          padding: 32px 64px;
          ${flex("center column")}
        `}
      >
        {isLoading ? (
          <Loader isLoading={isLoading} />
        ) : (
          <>
            <HandWaving />
            <Text
              h2
              center
              css={`
                margin: 16px 0;
              `}
            >
              Missing Information
            </Text>
            <Text
              label
              center
              css={`
                margin: 8px 0;
              `}
            >
              Please provide us with some additional details about your
              situation so that we can show you the plan rates.
            </Text>
            <Button
              className={css`
                margin: 16px 32px;
                margin-bottom: 32px;
                max-width: 300px;
                padding: 16px 24px;
              `}
              style={{
                ...(styles?.button || {}),
                backgroundColor: `var(--button-background-color, ${colors.purple})`,
              }}
              onClick={toggleDecisionTool}
            >
              Add Information
            </Button>
          </>
        )}
      </Div>
    );
  }

  const { Summary } = props;
  const costProps = {
    ...props?.props,
    data: {
      ...props.data,
      product: {
        ...props.data.product,
        Cost: value,
      },
    },
  };

  return <Summary {...costProps} premiumsSetExternally={false} />;
};
