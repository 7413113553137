import React, { useState, useEffect } from "react";
import { css } from "emotion";
import { X, Check } from "phosphor-react";
import getVisitor from "../lib/fingerprint/fingerprint";
import { CustomAxios } from "../axios/axios";
import TextField from "@material-ui/core/TextField";
import { useStore } from "./store-provider/use-store";
import { Button } from "@material-ui/core";

const url = "public/v1/bp/confidence_survey";

export const CTESection = ({ color, forceShowThankYou = false }) => {
  const [selectedValue, setSelectedValue] = React.useState("");
  const [feedback, setFeedback] = React.useState("");

  const [hasSent, setHasSent] = useState(false);
  const [alreadyDone, setAlreadyDone] = useState(false);

  const {
    data: { pages },
  } = useStore();

  useEffect(() => {
    const saved = localStorage.getItem("sentFeedback");
    if (saved) {
      setAlreadyDone(true);
    }
  }, []);

  const sendSurvey = async () => {
    try {
      let v = await getVisitor();

      let busID = "";
      let guideID = "";

      if (pages && pages.length > 0) {
        let page = pages[0];
        busID = page.BusinessID;
        guideID = page.CourseID;
      }

      let headers = {
        "Accept-Profile": "brite_public",
        "Content-Profile": "brite_public",
        BusinessID: busID,
      };

      await CustomAxios.post(
        url,
        {
          business_id: busID,
          course_id: guideID,
          visitor_id: v.visitorId,
          confident: selectedValue === "Yes" ? true : false,
          feedback,
        },
        {
          headers,
        }
      );
      try {
        localStorage.setItem("sentFeedback", "yes");
      } catch (e) {
        console.warn(e);
      }

      setHasSent(true);
    } catch (err) {
      console.warn(err);
    }
  };

  if (alreadyDone) {
    if (!forceShowThankYou) {
      return null;
    } else {
      return (
        <div
          className={css`
            border-radius: 16px;
            background: var(--100, #f5f7fa);
            padding: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 24px;
            flex-wrap: wrap;
          `}
        >
          <div
            className={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              gap: 24px;
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="52"
              height="52"
              viewBox="0 0 52 52"
              fill="none"
            >
              <path
                d="M26 7.49425e-08C21.4845 -0.000342722 17.0468 1.17532 13.1237 3.41123C9.2007 5.64715 5.92759 8.86622 3.62669 12.7515C1.32579 16.6368 0.0764314 21.0544 0.00161662 25.5692C-0.0731981 30.0841 1.02911 34.5406 3.20002 38.5L1.07502 45.975C0.875166 46.6628 0.862478 47.3915 1.03827 48.0859C1.21406 48.7803 1.57195 49.4151 2.07502 49.925C2.57806 50.4305 3.20855 50.7903 3.89959 50.9664C4.59063 51.1424 5.31645 51.1281 6.00002 50.925L13.5 48.8C16.9757 50.705 20.841 51.7901 24.8003 51.9725C28.7597 52.1548 32.7083 51.4295 36.3444 49.852C39.9805 48.2746 43.2078 45.8867 45.7796 42.8708C48.3515 39.855 50.1998 36.2911 51.1833 32.4515C52.1668 28.612 52.2594 24.5984 51.4541 20.7175C50.6489 16.8366 48.967 13.1913 46.537 10.06C44.107 6.92869 40.9934 4.39441 37.4339 2.65084C33.8745 0.907262 29.9636 0.00054902 26 7.49425e-08ZM34 32H18C17.4696 32 16.9609 31.7893 16.5858 31.4142C16.2107 31.0391 16 30.5304 16 30C16 29.4696 16.2107 28.9609 16.5858 28.5858C16.9609 28.2107 17.4696 28 18 28H34C34.5305 28 35.0392 28.2107 35.4142 28.5858C35.7893 28.9609 36 29.4696 36 30C36 30.5304 35.7893 31.0391 35.4142 31.4142C35.0392 31.7893 34.5305 32 34 32ZM34 24H18C17.4696 24 16.9609 23.7893 16.5858 23.4142C16.2107 23.0391 16 22.5304 16 22C16 21.4696 16.2107 20.9609 16.5858 20.5858C16.9609 20.2107 17.4696 20 18 20H34C34.5305 20 35.0392 20.2107 35.4142 20.5858C35.7893 20.9609 36 21.4696 36 22C36 22.5304 35.7893 23.0391 35.4142 23.4142C35.0392 23.7893 34.5305 24 34 24Z"
                fill={`${color}`}
              />
            </svg>
            <div
              className={css`
                color: var(--700, #25282d);
                text-align: center;
                font-feature-settings: "clig" off, "liga" off;

                /* H2 */
                font-family: Roboto;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px; /* 133.333% */
              `}
            >
              Thank you!
            </div>
            <div
              className={css`
                color: var(--700, #25282d);
                font-feature-settings: "clig" off, "liga" off;

                /* Body - Regular */
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
                letter-spacing: 0.15px;
              `}
            >
              Your feedback helps us improve our education.
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div
      className={css`
        border-radius: 16px;
        background: var(--100, #f5f7fa);
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        flex-wrap: wrap;
      `}
    >
      {!hasSent && (
        <>
          {" "}
          <div
            className={css`
              color: var(--700, #25282d);
              font-feature-settings: "clig" off, "liga" off;
              /* H4 */
              font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px; /* 155.556% */
              letter-spacing: 0.15px;
            `}
          >
            Do you feel confident to select your benefits?
          </div>
          <div
            className={css`
              display: flex;
              align-items: center;
              gap: 16px;
            `}
          >
            <div
              className={css`
                border-radius: 8px;
                border: ${selectedValue === "Yes"
                  ? "1px solid #3DC65E"
                  : "1px solid  #9aa7b5"};
                background: ${selectedValue === "Yes" ? "#3DC65E" : "#fff"};
                height: 44px;
                padding: 0px 32px 0px 24px;
                display: flex;
                gap: 8px;
                align-items: center;
                cursor: pointer;

                &:hover {
                  border: 2px solid var(--600, #25282d);
                  margin: -1px;
                }
              `}
              onClick={() => setSelectedValue("Yes")}
            >
              <div
                className={css`
                  height: 24px;
                `}
              >
                <Check
                  color={`${selectedValue === "Yes" ? "white" : "#25282d"}`}
                  size={24}
                />
              </div>
              <div
                className={css`
                  color: ${selectedValue === "Yes" ? "white" : "#25282d"};
                  text-align: center;
                  font-feature-settings: "clig" off, "liga" off;
                  /* H4 */
                  font-family: Roboto;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 28px; /* 155.556% */
                  letter-spacing: 0.15px;
                `}
              >
                Yes
              </div>
            </div>
            <div
              className={css`
                border-radius: 8px;
                border: ${selectedValue === "No"
                  ? "1px solid #EB4E3D"
                  : "1px solid  #9aa7b5"};
                background: ${selectedValue === "No" ? "#EB4E3D" : "white"};
                height: 44px;
                padding: 0px 32px 0px 24px;
                display: flex;
                gap: 8px;
                align-items: center;
                cursor: pointer;

                &:hover {
                  border: 2px solid var(--600, #25282d);
                  margin: -1px;
                }
              `}
              onClick={() => setSelectedValue("No")}
            >
              <div
                className={css`
                  height: 24px;
                `}
              >
                <X
                  color={`${selectedValue === "No" ? "white" : "#25282d"}`}
                  size={24}
                />
              </div>
              <div
                className={css`
                  color: ${selectedValue === "No" ? "white" : "#25282d"};
                  text-align: center;
                  font-feature-settings: "clig" off, "liga" off;
                  /* H4 */
                  font-family: Roboto;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 700;

                  letter-spacing: 0.15px;
                `}
              >
                No
              </div>
            </div>
          </div>
          {selectedValue && (
            <div
              className={css`
                width: 100%;
                flex: 1;
              `}
            >
              <div
                className={css`
                  color: var(--700, #25282d);
                  font-feature-settings: "clig" off, "liga" off;
                  /* Body - Regular */
                  font-family: Roboto;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px; /* 150% */
                  letter-spacing: 0.15px;
                `}
              >
                {selectedValue === "Yes"
                  ? `Hey! We’d love to know what you found most helpful about this guide.`
                  : `Dang. We’d really like to know how we could have made this guide better?`}
              </div>
              <div>
                <TextField
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "8px",
                  }}
                  id="standard-multiline-flexible"
                  placeholder="Leave your feedback"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={feedback}
                  onChange={(e) => {
                    setFeedback(e.target.value);
                  }}
                />
              </div>
              <div
                className={css`
                  margin-top: 12px;
                  display: flex;
                  justify-content: end;
                `}
              >
                <Button
                  size="large"
                  variant="outlined"
                  onClick={sendSurvey}
                  disabled={!selectedValue}
                  style={{ backgroundColor: "white" }}
                >
                  Submit Feedback
                </Button>
              </div>
            </div>
          )}
        </>
      )}

      {hasSent && (
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 24px;
          `}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
          >
            <path
              d="M26 7.49425e-08C21.4845 -0.000342722 17.0468 1.17532 13.1237 3.41123C9.2007 5.64715 5.92759 8.86622 3.62669 12.7515C1.32579 16.6368 0.0764314 21.0544 0.00161662 25.5692C-0.0731981 30.0841 1.02911 34.5406 3.20002 38.5L1.07502 45.975C0.875166 46.6628 0.862478 47.3915 1.03827 48.0859C1.21406 48.7803 1.57195 49.4151 2.07502 49.925C2.57806 50.4305 3.20855 50.7903 3.89959 50.9664C4.59063 51.1424 5.31645 51.1281 6.00002 50.925L13.5 48.8C16.9757 50.705 20.841 51.7901 24.8003 51.9725C28.7597 52.1548 32.7083 51.4295 36.3444 49.852C39.9805 48.2746 43.2078 45.8867 45.7796 42.8708C48.3515 39.855 50.1998 36.2911 51.1833 32.4515C52.1668 28.612 52.2594 24.5984 51.4541 20.7175C50.6489 16.8366 48.967 13.1913 46.537 10.06C44.107 6.92869 40.9934 4.39441 37.4339 2.65084C33.8745 0.907262 29.9636 0.00054902 26 7.49425e-08ZM34 32H18C17.4696 32 16.9609 31.7893 16.5858 31.4142C16.2107 31.0391 16 30.5304 16 30C16 29.4696 16.2107 28.9609 16.5858 28.5858C16.9609 28.2107 17.4696 28 18 28H34C34.5305 28 35.0392 28.2107 35.4142 28.5858C35.7893 28.9609 36 29.4696 36 30C36 30.5304 35.7893 31.0391 35.4142 31.4142C35.0392 31.7893 34.5305 32 34 32ZM34 24H18C17.4696 24 16.9609 23.7893 16.5858 23.4142C16.2107 23.0391 16 22.5304 16 22C16 21.4696 16.2107 20.9609 16.5858 20.5858C16.9609 20.2107 17.4696 20 18 20H34C34.5305 20 35.0392 20.2107 35.4142 20.5858C35.7893 20.9609 36 21.4696 36 22C36 22.5304 35.7893 23.0391 35.4142 23.4142C35.0392 23.7893 34.5305 24 34 24Z"
              fill={`${color}`}
            />
          </svg>
          <div
            className={css`
              color: var(--700, #25282d);
              text-align: center;
              font-feature-settings: "clig" off, "liga" off;

              /* H2 */
              font-family: Roboto;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 32px; /* 133.333% */
            `}
          >
            Thank you!
          </div>
          <div
            className={css`
              color: var(--700, #25282d);
              font-feature-settings: "clig" off, "liga" off;

              /* Body - Regular */
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */
              letter-spacing: 0.15px;
            `}
          >
            Your feedback helps us improve our education.
          </div>
        </div>
      )}
    </div>
  );
};
