import { CircularProgress } from "@material-ui/core";
import { css } from "emotion";
import { Div } from "./div";

export const Loader = () => {
  return (
    <Div styles="jc:c ai:c" className={css`width: 100%; height: 100%; padding: 8px;`}>
      <CircularProgress />
    </Div>
  );
}