import React from "react";
import { getAttributes } from "../utils";
import { CroppedImage } from "./cropped-image";
import { useHandleAction } from "./handle-action";

export const Image = ({ item, containerAttributes, totalColumns }) => {
  const componentAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: "component",
  });

  const { className } = componentAttributes;

  const LinkedImage = ({ children }) => (
    <>
      { item?.action ? (
        <a
          onClick={handleAction}
          className={className}
          style={{ cursor: "pointer" }}
        >
          {children}
        </a>
      ) : item?.link ? (
          <a
            target={item?.attributes?.target || "_blank"}
            href={item?.attributes?.href}
            className={className}
          >
            {children}
          </a>
        ) : (
          children
        )}
    </>
  );

  const handleAction = useHandleAction(item);

  return (
    <LinkedImage>
      {item?.crop ? (
        <CroppedImage
          style={componentAttributes?.style}
          width={containerAttributes?.style?.width}
          crop={item?.crop}
          imageProps={{
            src: componentAttributes?.src,
            alt: componentAttributes?.alt,
          }}
        />
      ) : (
        <img {...componentAttributes} />
      )}
    </LinkedImage>
  );
};
