import React, { useMemo } from "react";
import { motion } from "framer-motion";
import { css } from "emotion";
import { Text } from "../../shared-components";
import { CustomBenefit } from "./CustomBenefit";
import { colors } from "../../shared-components/styles";
import { flex } from "../../shared-components/shared-styles";
import { Star } from "phosphor-react";
import { benefitListContainer } from "./additional-benefits/product-components/shared-styles";

export const CustomProductsList = (props) => {
  const recList = props?.recommendedCustomProducts?.map(({ ID }) => ID);

  const productLists = useMemo(() => {
    return props?.allCustomProducts?.reduce(
      (prev, item) => {
        if (recList.includes(item?.ID)) {
          return {
            ...prev,
            recs: [...prev?.recs, item],
          };
        } else {
          return {
            ...prev,
            nonRecs: [...prev?.nonRecs, item],
          };
        }
      },
      {
        recs: [],
        nonRecs: [],
      }
    );
  }, [recList]);

  if (!productLists?.recs?.length && !productLists?.nonRecs?.length) {
    return null;
  }

  const recCount = props?.recommendedCustomProducts?.length;

  return (
    <motion.div
      key={"custom-products-list"}
      initial="pageInitial"
      animate="pageAnimate"
      exit="pageExit"
      variants={{
        pageInitial: {
          x: 400,
          opacity: 0,
        },
        pageAnimate: {
          x: 0,
          opacity: 1,
        },
        pageExit: {
          x: -400,
          opacity: 0,
        },
      }}
      className={benefitListContainer}
    >
      <div
        className={css`
          background-color: white;
          border: 1px solid ${colors.gray[300]};
          border-radius: 16px;
          padding: 32px;
        `}
      >
        <div
          className={css`
            ${flex("space-between")}
            margin-bottom: 32px;
          `}
        >
          <Text h1>Other Benefits</Text>
          {productLists?.recs?.length ? (
            <div
              className={css`
                border-radius: 30px;
                background-color: var(--button-background-color);
                padding: 8px 16px;
                ${flex("left")}
              `}
            >
              <Star size={16} color="var(--button-text-color)" weight="fill" />
              <Text
                css={`
                  color: var(--button-text-color);
                  margin-left: 8px;
                `}
              >
                Recommended
              </Text>
            </div>
          ) : null}
        </div>

        {productLists?.recs?.length > 0 ? (
          <div
            className={css`
              ${flex("left")}
              padding: 16px;
              background-color: ${colors.gray[100]};
              border-radius: 16px;
              margin: 24px 0;
            `}
          >
            <Text label>
              There {productLists?.recs?.length === 1 ? "is" : "are"}{" "}
              {productLists?.recs?.length} additional benefit
              {productLists?.recs?.length === 1 ? "" : "s"} we think you'll find
              valuable.
            </Text>
          </div>
        ) : null}

        <div>
          {productLists?.recs?.map((product) => (
            <CustomBenefit
              product={product}
              selected={props.selected}
              setSelected={props.setSelected}
              recommended={recList?.includes(product?.ID)}
            />
          ))}

          {productLists?.nonRecs?.length ? (
            <div
              className={css`
                ${flex("left")}
                padding: 16px;
                background-color: ${colors.gray[100]};
                border-radius: 16px;
                margin: 24px 0;
              `}
            >
              <Text label>
                There {productLists?.nonRecs?.length === 1 ? "is" : "are"}{" "}
                {productLists?.nonRecs?.length} more benefit
                {productLists?.nonRecs?.length === 1 ? "" : "s"} your company
                offers.
              </Text>
            </div>
          ) : null}
          {productLists?.nonRecs?.map((product) => (
            <CustomBenefit
              product={product}
              selected={props.selected}
              setSelected={props.setSelected}
              recommended={recList?.includes(product?.ID)}
            />
          ))}
        </div>
      </div>
    </motion.div>
  );
};
