import React from "react";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BriteRadio from "./inputs/BriteRadio";

import Slider from "@material-ui/core/Slider";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import { css } from "emotion";
import { toast } from "react-toastify";

import { motion } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const BriteSlider = withStyles({
  root: {
    color: "#25282D",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#25282D",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    backgroundColor: "#E8EDF3",
    height: 8,
    borderRadius: 4,
  },
})(Slider);

export const DecisionToolScaleQuestion = (props) => {
  const classes = useStyles();

  console.log("props scale", props);

  const getScale = () => {
    if (props.enrolling === props.customEmployeeLabel) {
      if (
        props.question.AlternateScales &&
        props.question.AlternateScales.EmployeeOnly
      ) {
        return props.question.AlternateScales.EmployeeOnly;
      } else {
        return props.question.DefaultScale;
      }
    } else if (props.enrolling === props.customEmployeeSpouseLabel) {
      if (
        props.question.AlternateScales &&
        props.question.AlternateScales.EmployeeSpouse
      ) {
        return props.question.AlternateScales.EmployeeSpouse;
      } else {
        return props.question.DefaultScale;
      }
    } else if (props.enrolling === props.customEmployeeChildrenLabel) {
      if (
        props.question.AlternateScales &&
        props.question.AlternateScales.EmployeeChildren
      ) {
        return props.question.AlternateScales.EmployeeChildren;
      } else {
        return props.question.DefaultScale;
      }
    } else if (props.enrolling === props.customFamilyLabel) {
      if (
        props.question.AlternateScales &&
        props.question.AlternateScales.Family
      ) {
        return props.question.AlternateScales.Family;
      } else {
        return props.question.DefaultScale;
      }
    } else {
      return props.question.DefaultScale;
    }
  };

  React.useEffect(() => {
    if (getScale().StartingValue) {
      props.setValue({
        Min: getScale().Low,
        Max: getScale().High,
        Value: getScale().StartingValue,
      });
    }
  }, []);

  return (
    <motion.div
      key={props.question.QuestionText}
      initial="pageInitial"
      animate="pageAnimate"
      exit="pageExit"
      variants={{
        pageInitial: {
          y: 400,
          opacity: 0,
        },
        pageAnimate: {
          y: 0,
          opacity: 1,
        },
        pageExit: {
          y: -400,
          opacity: 0,
        },
      }}
      className={css`
        background: #ffffff;

        box-shadow: 0px 2px 8px rgba(37, 40, 45, 0.25);
        border-radius: 8px;
        padding: 24px;
        margin-bottom: 32px;
        text-align: left;
      `}
    >
      <h1
        className={css`
          color: #25282d;
          font-size: 23px;
          line-height: 32px;
          margin-block-start: 0px;
          margin-block-end: 24px;
        `}
      >
        {" "}
        {props.question.QuestionText}
      </h1>
      <div
        className={css`
          margin-top: 48px;
        `}
      >
        <BriteSlider
          valueLabelDisplay={props.question.ShowNumbers ? "on" : "off"}
          marks={props.question.ShowTicks}
          aria-label="brite slider input"
          value={props.value ? props.value.Value : getScale().StartingValue}
          min={getScale().Low}
          track={props.question.ShowTrack}
          max={getScale().High}
          valueLabelFormat={(x) => {
            let label = "";
            if (props.question.ScaleUnits) {
              label += props.question.ScaleUnits;
            }
            if (x) {
              label += x;
            } else {
              label += getScale().StartingValue || 0;
            }

            return label;
          }}
          onChange={(e, val) => {
            props.setValue({
              Min: getScale().Low,
              Max: getScale().High,
              Value: Number(val),
            });
          }}
        />

        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 52px;
            @media (max-width: 420px) {
              gap: 14px;
            }
            margin-top: 24px;
          `}
        >
          <div
            className={css`
              flex: 1;
              text-align: center;
              padding-left: 32px;
              @media (max-width: 420px) {
                padding-left: 0px;
              }
            `}
          >
            {props.question.LowInfoText}
          </div>

          <div
            className={css`
              flex: 1;
              text-align: center;
              padding-right: 32px;
              @media (max-width: 420px) {
                padding-right: 0px;
              }
            `}
          >
            {props.question.HighInfoText}
          </div>
        </div>
      </div>
    </motion.div>
  );
};
