import { TemplateViewer } from "./template-viewer";
import { Viewer } from "./viewer";
import { PDFViewer } from "./pdf/PDFViewer";

export const ViewerRenderer = () => {
  const [path, id] = window.location.pathname.substring(1).split("/");

  switch (path) {
    case "brite-template-preview":
      return id ? <TemplateViewer templateId={id} /> : null;
    case "pdf-preview":
      return id ? <PDFViewer guideId={id} /> : null;
    default:
      return <Viewer />;
  }
};
