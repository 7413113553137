// EMPLOYEE-VIEW
import { css, cx } from "emotion";
import { CustomAxios } from "../../../../axios/axios";
import { Div, Text } from "../../../../shared-components";
import { animation, flex } from "../../../../shared-components/shared-styles";
import { colors } from "../../../../shared-components/styles";

export const CarrierLogo = ({
  className = "",
  planCarrierName,
  carrierID,
  maxWidth = "100%",
  maxHeight = "80px",
  position = "top right",
}) => {
  const hasCarrierWithoutLogo =
    (!carrierID || carrierID === "00000000-0000-0000-0000-000000000000") &&
    planCarrierName;
  const id = carrierID || "00000000-0000-0000-0000-000000000000";
  return (
    <Div
      className={cx(
        css`
          ${animation("fadeIn", ".5s ease")}
          max-width: ${maxWidth};
          height: ${maxHeight};
          box-sizing: border-box;
          padding: 8px;
          ${hasCarrierWithoutLogo
            ? `
            ${flex("center")}
            height: 64px;
            border-radius: 8px;
            border: 1px solid ${colors.gray[300]};
            width: 100%;
          `
            : ""}
          img {
            object-fit: contain;
            object-position: ${position};
            width: 100%;
            height: 100%;
          }
        `,
        className
      )}
    >
      {hasCarrierWithoutLogo ? (
        <Text styles="h2">{planCarrierName}</Text>
      ) : (
        <img
          src={`${CustomAxios.baseURL()}public/v1/business/${id}/logo`}
          alt="Carrier"
        />
      )}
    </Div>
  );
};
