import { css } from "emotion";
import { MapPin } from "phosphor-react";
import { Button, Div, Text } from "../../../../../shared-components";
import {
  container,
  flex,
} from "../../../../../shared-components/shared-styles";
import { colors } from "../../../../../shared-components/styles";

export const formatUrl = (text) => {
  let url = text;
  if (!/^https?:\/\//i.test(url) && !/^http?:\/\//i.test(url)) {
    url = "https://" + url;
  }
  try {
    const data = new URL(url);
    return [data.href, true];
  } catch (err) {
    return ["", false];
  }
};

export const NetworkSearch = ({ data }) => {
  const openSearch = (url) => {
    const [link, isValid] = formatUrl(url);
    if (isValid) {
      window.open(link, "_blank");
    }
  };

  const {
    IsMultiCarrier,
    MultiCarrierNetworkName,
    MultiCarrierNetworkCarrierSearchUrl,
    NetworkName,
    NetworkDescription,
    NetworkProviderSearchUrl,
  } = data?.product?.Details || {};

  const {
    ProductNetworkName,
    ProductNetworkDescription,
    ProductNetworkProviderSearchUrl,
  } = data?.product || {};

  return (
    <Div
      css={css`
        ${container.box}
        width: 100%;
        padding: 24px 32px;
      `}
    >
      <Div
        css={css`
          ${flex("space-between")}
        `}
      >
        <Text
          css={`
            width: min-content;
            border-radius: 30px;
            background-color: var(--accent-color-4);
            color: var(--accent-color-1);
            padding: 4px 16px;
            margin-right: 16px;
            font-weight: bold;
          `}
        >
          Network
        </Text>
        {IsMultiCarrier ? (
          <Text label bold>
            {ProductNetworkName || NetworkName
              ? ProductNetworkName || NetworkName
              : ""}
            {MultiCarrierNetworkName ? ` | ${MultiCarrierNetworkName}` : ""}
          </Text>
        ) : ProductNetworkName || NetworkName ? (
          <Text label bold>
            {ProductNetworkName || NetworkName}
          </Text>
        ) : null}
      </Div>
      <Div
        css={css`
          padding-top: 32px;
        `}
      >
        <Div
          css={css`
            ${flex("left")}
            p {
              margin-left: 16px;
            }
          `}
        >
          <MapPin />
          <Text h2>Search for network providers</Text>
        </Div>
        <Text
          css={`
            margin: 16px 0;
          `}
        >
          {!IsMultiCarrier
            ? ProductNetworkDescription || NetworkDescription
            : ""}
        </Text>
        {!IsMultiCarrier && (
          <Button
            onClick={() => {
              openSearch(
                ProductNetworkProviderSearchUrl || NetworkProviderSearchUrl
              );
            }}
            css={`
              background-color: var(--accent-color-1);
            `}
          >
            Search Network
          </Button>
        )}
        {IsMultiCarrier && (
          <div
            className={css`
              display: flex;
              gap: 16px;
              flex-wrap: wrap;
            `}
          >
            <Button
              onClick={() => {
                openSearch(
                  ProductNetworkProviderSearchUrl || NetworkProviderSearchUrl
                );
              }}
              css={`
                background-color: var(--accent-color-1);
              `}
            >
              Search {ProductNetworkName || NetworkName}
            </Button>
            <Button
              onClick={() => {
                openSearch(MultiCarrierNetworkCarrierSearchUrl);
              }}
              css={`
                background-color: var(--accent-color-1);
              `}
            >
              Search {MultiCarrierNetworkName}
            </Button>
          </div>
        )}
      </Div>
    </Div>
  );
};
