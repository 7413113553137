import { css } from "emotion";
import { ListBullets } from "phosphor-react";
import { useMemo, useState } from "react";
import { Button, Div, Text } from "../../../shared-components";
import { transformFields } from "../../../shared-components/product-components/configs";
import { RecommendationFieldList } from "../../../shared-components/product-components/recommendation-field-list";
import { flex } from "../../../shared-components/shared-styles";
import { colors } from "../../../shared-components/styles";
import { FieldComponent } from "../../brite-viewer/components/benefits/field-component";
import { flattenSummaryFields } from "../../brite-viewer/components/benefits/product-layout-constants";
import { useStore } from "../../store-provider/use-store";

const basicFieldContainer = css`
  ${flex("space-between")}
  flex-grow: 1;
  padding: 16px;
`;

const medicalRecTransformer = ({ product }) => ({
  "Label.InNetworkPlanDetails": {
    components: [
      ({ field, props }) => (
        <Div
          css={css`
            flex-grow: 1;
          `}
        >
          <Div css={basicFieldContainer} className="alternating-item">
            <Text h3 bold>
              {field?.DisplayValue}
            </Text>
          </Div>
          {props?.showDefinitions ? (
            <Div
              css={css`
                padding: 16px;
                margin: 24px;
                border: 1px solid ${colors.gray[300]};
                border-radius: 8px;
              `}
            >
              <Text bold>What's a deductible?</Text>
              <Text>
                A deductible is a specific dollar amount your health insurance
                plan may require you to pay before insurance begins to pay.
              </Text>
            </Div>
          ) : null}
        </Div>
      ),
    ],
  },
  "Details.InNetworkPlanDesign.Coinsurance": {
    components: [
      ({ field, props }) => (
        <Div
          css={css`
            flex-grow: 1;
          `}
        >
          <Div css={basicFieldContainer} className="alternating-item">
            <Text label>{field?.DisplayValue}</Text>
            <FieldComponent product={product} field={field} />
          </Div>
          {props?.showDefinitions ? (
            <Div
              css={css`
                padding: 16px;
                margin: 24px;
                border: 1px solid ${colors.gray[300]};
                border-radius: 8px;
              `}
            >
              <Text bold>What's is Co-Insurance?</Text>
              <Text>
                Coinsurance is the percentage of costs of a covered health care
                service your health insurance plan pays after you've paid your
                deductible.
              </Text>
            </Div>
          ) : null}
        </Div>
      ),
    ],
  },
  "Details.InNetworkPlanDesign.SingleOOPM": {
    components: [
      ({ field, props }) => (
        <Div
          css={css`
            flex-grow: 1;
          `}
        >
          <Div css={basicFieldContainer} className="alternating-item">
            <Text label>{field?.DisplayValue}</Text>
            <FieldComponent product={product} field={field} />
          </Div>
          {props?.showDefinitions ? (
            <Div
              css={css`
                padding: 16px;
                margin: 24px;
                border: 1px solid ${colors.gray[300]};
                border-radius: 8px;
              `}
            >
              <Text bold>What is an Out-Of-Pocket Maximum?</Text>
              <Text>
                An OOPM (Out-Of-Pocket Maximum) is a set amount of money you
                will have to pay in a year on covered medical costs. Once you
                have met your OOPM, insurance pays for 100% of covered medical
                expenses.
              </Text>
            </Div>
          ) : null}
        </Div>
      ),
    ],
  },
  "Label.InNetworkProfessionalServices": {
    components: [
      ({ field, props }) => (
        <Div
          css={css`
            flex-grow: 1;
          `}
        >
          <Div
            css={css`
              ${basicFieldContainer} margin-top: 8px;
            `}
          >
            <Text h2>{field?.DisplayValue}</Text>
          </Div>
          {props?.showDefinitions ? (
            <Div
              css={css`
                padding: 16px;
                margin: 24px;
                border: 1px solid ${colors.gray[300]};
                border-radius: 8px;
              `}
            >
              <Text bold>What are In-Network Professional Services?</Text>
              <Text>This is what you (the member) are responsible for.</Text>
            </Div>
          ) : null}
        </Div>
      ),
    ],
  },
  "Label.PrescriptionDrugs": {
    components: [
      ({ field, props }) => (
        <Div
          css={css`
            ${basicFieldContainer} margin-top: 8px;
          `}
        >
          <Text h2>{field?.DisplayValue}</Text>
        </Div>
      ),
    ],
  },
});

export const MedicalDetails = ({ productId }) => {
  const {
    data: { products, productLayouts },
  } = useStore();

  const [showDefinitions, setShowDefinitions] = useState(false);

  const { product, layout } = useMemo(() => {
    const product = products.find(({ ID }) => ID === productId);
    const layout = productLayouts?.[productId] || {};
    return { product, layout };
  }, [productId]);

  const fieldsList = useMemo(() => {
    const list = flattenSummaryFields(product, layout.Layout);
    const showList = list.filter(
      (item) => item.State !== "hide" && item?.itemType !== "section"
    );
    return showList;
  }, [product?.ID]);

  const { fields } = transformFields(
    product,
    fieldsList,
    medicalRecTransformer
  );

  return (
    <>
      <Div
        css={css`
          ${flex("space-between")} margin: 16px 32px;
        `}
      >
        <Div
          css={css`
            ${flex("left")}
            p {
              margin-left: 16px;
            }
          `}
        >
          <ListBullets />
          <Text h2>Plan Details</Text>
        </Div>
        <Button secondary onClick={() => setShowDefinitions(!showDefinitions)}>
          {showDefinitions ? "Hide Definitions" : "Show Definitions"}
        </Button>
      </Div>
      <Div styles="alternatingList">
        <RecommendationFieldList
          product={product}
          fields={fields}
          props={{ showDefinitions, setShowDefinitions }}
        />
      </Div>
    </>
  );
};
