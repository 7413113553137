import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { CustomAxios } from "../axios/axios";

export function useAPIHook(url, errorMessage) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      setLoading(true);
      try {
        const result = await CustomAxios.get(url);
        if (!ignore) {
          setError(false);
          setData(result.data);
        }
      } catch (e) {
        if (!ignore) {
          setError(true);
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } finally {
        if (!ignore) {
          setLoading(false);
        }
      }
    }

    fetchData();
    return () => {
      ignore = true;
    };
  }, [url, errorMessage]);

  return { data, loading, error };
}
