import { IconContext } from "phosphor-react";
import { colors } from "../shared-components/styles";
import { StoreProvider } from "./store-provider/provider";
import { ViewerRenderer } from "./ViewerRenderer";

export const App = () => {
  // Set defaults for all phosphor icons.
  const phosphorTheme = {
    color: colors.black,
    size: 32,
    weight: "regular",
    mirrored: false,
  };

  return (
    <IconContext.Provider value={phosphorTheme}>
      <StoreProvider>
        <ViewerRenderer />
      </StoreProvider>
    </IconContext.Provider>
  );
};
