import { colors } from "./styles";

// The flex function is a flex-box shorthand that returns a css-style-string
// using the flexOptions below

// Usage:
// flex('jcsb aic column')

// Returns:
//  display: flex;
//  justify-content: space-between;
//  align-items: center;
// flex-direction: column;

export const flex = (options) => {
  const values = options?.split(" ") || [];
  const style = values.reduce((prev, item) => {
    return prev + " " + flexOptions?.[item] || "";
  }, `display: flex;`);
  return style;
};

const flexOptions = {
  jcsb: `justify-content: space-between;`,
  jcsa: `justify-content: space-around;`,
  jcse: `justify-content: space-evenly;`,
  jcc: `justify-content: center;`,
  jcl: `justify-content: left;`,
  jcr: `justify-content: right;`,
  aic: `align-items: center;`,
  ais: `align-items: start;`,
  aie: `align-items: end;`,
  aistretch: `align-items: stretch;`,

  "space-between": `justify-content: space-between; align-items: center;`,
  "space-around": `justify-content: space-around; align-items: center;`,
  "space-evenly": `justify-content: space-evenly; align-items: center;`,
  center: `justify-content: center; align-items: center;`,
  left: `justify-content: left; align-items: center;`,
  right: `justify-content: right; align-items: center;`,
  "ai-center": `align-items: center;`,
  start: `align-items: start;`,
  end: `align-items: end;`,
  stretch: `align-items: stretch;`,

  column: `flex-direction: column;`,
  grow: `flex-grow: 1;`,
  wrap: `flex-wrap: wrap;`,
};

export const scrollbar = {
  hide: `::-webkit-scrollbar { display: none; }`,
  style: `
    scroll-behavior: smooth;
    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: white;
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.black};
      border-radius: 16px;
      visibility: visible;
    }
  `,
};

export const px = {
  xs: "2px",
  sm: "4px",
  md: "8px",
  lg: "16px",
  xl: "32px",
};

export const borderRadius = {
  xs: `border-radius: 2px;`,
  sm: `border-radius: 4px;`,
  md: `border-radius: 8px;`,
  lg: `border-radius: 16px;`,
  xl: `border-radius: 32px;`,
};

export const shadows = {
  sm: `filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, .1));`,
  md: `filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, .2));`,
  lg: `filter: drop-shadow(4px 4px 12px rgba(0, 0, 0, .3));`,
};

const animations = {
  fadein: `@keyframes fadein { from { opacity: 0; } to { opacity: 1; } }`,
  blurin: `@keyframes blurin { from { backdrop-filter: blur(2px); } to { backdrop-filter: none; } }`,
};

export const animation = (type, options) => {
  return `
    ${animations[type]}
    animation: ${type} ${options};
  `;
};

export const container = {
  box: `
    padding: 8px 16px;
    border-radius: 8px;
    background-color: white;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, .2));
  `,
  header: `
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    h1 {
      font-weight: bold;
      font-size: 34px;
      line-height: 42px;
      color: #25282d;
      margin: 0;
      padding: 0;
      margin-right: 12px;
      margin-left: 8px;
    }
  `,
  alternatingList: `
    border-bottom: 1px solid #d1dae3;
    > div {
      padding: 16px 8px;
      :nth-child(odd) {
        background-color: #f5f7fa;
      }
    }
  `,
  list: `
    .alt-item {
      :nth-of-type(odd) { background-color: ${colors.gray[100]}; }
      padding: 8px;
      border-radius: 8px;
    }
  `,
  hover: `
    cursor: pointer;
    :hover { background-color: ${colors.gray[100]}; }
    transition: background-color .2s ease;
  `,
};
