import { css, cx } from "emotion";
import React, { forwardRef } from "react";
import { buildStyles, colors } from "./styles";

const divStyles = {
  box: `
    padding: 8px 16px;
    border-radius: 8px;
    background-color: white;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, .2));`,
  header: `
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    h1 {
      font-weight: bold;
      font-size: 34px;
      line-height: 42px;
      color: #25282d;
      margin: 0;
      padding: 0;
      margin-right: 12px;
      margin-left: 8px;
    }`,
  alternatingList: `
    .alternating-item {
      :nth-child(even) {
        background-color: ${colors.gray[100]};
      }
    }
  `,
  alternatingListOdd: `
    .alternating-item {
      :nth-child(odd) {
        background-color: ${colors.gray[100]};
      }
    }
  `,
};

export const Div = forwardRef(
  ({ children, styles = "", className = "", css: cssStyles, ...rest }, ref) => {
    const combinedStyles = cx(
      css`
        ${buildStyles(divStyles, styles)}
      `,
      cssStyles,
      className
    );
    const props = { className: combinedStyles, ...rest, ref };
    return <div {...props}>{children}</div>;
  }
);
