import React, { Component } from "react";
import { Text, TextInput } from "grommet";

import Button from "@material-ui/core/Button";

import { css } from "emotion";
import { motion } from "framer-motion";
import BriteTextInput from "./inputs/BriteTextInput";

export class DecisionToolDoctorVisitsQuestion extends Component {
  render() {
    return (
      <motion.div
        key={`drvisits_question${this.props.type}`}
        initial="pageInitial"
        animate="pageAnimate"
        exit="pageExit"
        variants={{
          pageInitial: {
            y: 400,
            opacity: 0,
          },
          pageAnimate: {
            y: 0,
            opacity: 1,
          },
          pageExit: {
            y: -400,
            opacity: 0,
          },
        }}
        className={css`
          background: #ffffff;

          box-shadow: 0px 2px 8px rgba(37, 40, 45, 0.25);
          border-radius: 8px;
          padding: 24px;
          margin-bottom: 32px;

          text-align: left;
        `}
      >
        <h1
          className={css`
            color: #25282d;
            font-size: 23px;
            line-height: 32px;
            margin-block-start: 0px;
            margin-block-end: 0px;
          `}
        >
          {" "}
          How many doctor visits do you anticipate this year?
          {!this.props.isSurvey && this.props.info}
        </h1>
        <div
          className={css`
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            letter-spacing: 0.15px;

            color: #66737f;
          `}
        >
          *Do not include preventative care visits.{" "}
        </div>

        {this.props.shouldDisplayDependentQuestions && (
          <div
            className={css`
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              /* identical to box height, or 133% */

              letter-spacing: 0.15px;

              color: #25282d;
              margin-top: 16px;
            `}
          >
            For you
          </div>
        )}
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 8px;
            flex-wrap: wrap;
          `}
        >
          <div
            className={css`
              margin-top: 16px;
            `}
          >
            <BriteTextInput
              value={this.props.numberOfficeVisits}
              id="office-input"
              label="Office"
              variant="outlined"
              type="number"
              placeholder="# of office visits"
              inputProps={{ inputMode: "numeric" }}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  this.props.setNumberOfficeVisits(e.target.value);
                }
              }}
            />
          </div>
          <div
            className={css`
              margin-top: 16px;
            `}
          >
            <BriteTextInput
              value={this.props.numberSpecialtyVisits}
              id="specialist-input"
              label="Specialist"
              variant="outlined"
              type="number"
              placeholder="# of specialist visits"
              inputProps={{ inputMode: "numeric" }}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  this.props.setNumberSpecialtyVisits(e.target.value);
                }
              }}
            />
          </div>
        </div>
        {this.props.shouldDisplayDependentQuestions && (
          <>
            <div
              className={css`
                font-family: "Roboto";
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                /* identical to box height, or 133% */

                letter-spacing: 0.15px;

                color: #25282d;
                margin-top: 24px;
              `}
            >
              For your dependents
            </div>
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 8px;
                flex-wrap: wrap;
              `}
            >
              <div
                className={css`
                  margin-top: 16px;
                `}
              >
                <BriteTextInput
                  value={this.props.dependantNumberOfficeVisits}
                  id="office-input"
                  label="Office"
                  variant="outlined"
                  type="number"
                  placeholder="# of office visits"
                  inputProps={{ inputMode: "numeric" }}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      this.props.setDependantNumberOfficeVisits(e.target.value);
                    }
                  }}
                />
              </div>
              <div
                className={css`
                  margin-top: 16px;
                `}
              >
                <BriteTextInput
                  value={this.props.dependantNumberSpecialtyVisits}
                  id="specialist-input"
                  label="Specialist"
                  variant="outlined"
                  type="number"
                  placeholder="# of specialist visits"
                  inputProps={{ inputMode: "numeric" }}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      this.props.setDependantNumberSpecialtyVisits(
                        e.target.value
                      );
                    }
                  }}
                />
              </div>
            </div>
          </>
        )}

        <div
          className={css`
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            /* identical to box height, or 200% */

            letter-spacing: 0.15px;

            color: #9aa7b5;
            margin-top: 16px;
          `}
        >
          *Your answer to this question will not affect your rate
        </div>
      </motion.div>
    );
  }
}
