import React, { useCallback, useMemo, useState } from "react";
import { motion } from "framer-motion";
import { css, cx } from "emotion";
import parse from "html-react-parser";
import { Typography } from "@material-ui/core";
import {
  benefitContainer,
  itemContainer,
  largeScreen,
  listContainer,
  smallScreen,
} from "../additional-benefits/product-components/shared-styles";
import {
  Bandaids,
  CurrencyCircleDollar,
  Eye,
  FaceMask,
  FirstAid,
  PlusCircle,
  UsersThree,
  X,
} from "phosphor-react";
import { posthogAttrs } from "../../../posthog-constants";
import { colors } from "../../../shared-components/styles";
import { flex, scrollbar } from "../../../shared-components/shared-styles";
import { CarrierLogo } from "../../brite-viewer/components/benefits/carrier-logo";
import { Text, Button, Modal } from "../../../shared-components";

const cardContainer = css`
  display: flex;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #d1dae3;
  border: none;
  margin: 0 16px;
  padding: 8px;
  p {
    margin-left: 16px;
  }
`;

export const FSAPlanViewer = (props) => {
  const [learnMoreOpen, setLearnMoreOpen] = useState(false);

  const titleDescriptions = useMemo(() => {
    if (props.benefit && props.benefit.TitleDescriptions) {
      return props.benefit.TitleDescriptions.filter(
        (item) => !item.Autogenerated && !!item.Title && !!item.Description
      );
    }
    return [];
  }, []);

  const logoUrl = (props.carrier && props.carrier.LogoURL) || "";

  const replaceURLWithHTMLLinks = (text) => {
    var exp = /(?:^|[^"'])((ftp|http|https|file):\/\/[\S]+(\b|$))/gi;
    return text.replace(exp, " <a href='$1' target='_blank'>$1</a>");
  };

  const parseDisclaimer = (disclaimer) => {
    return parse(replaceURLWithHTMLLinks(disclaimer));
  };

  const Logo = useCallback(
    ({ className }) => {
      return props.benefit?.ProviderID &&
        props.benefit?.ProviderID !== "00000000-0000-0000-0000-000000000000" ? (
        <div
          className={cx(
            className,
            css`
              border: 1px solid ${colors.gray[300]};
              border-radius: 8px;
              min-width: 164px;
              background-color: white;
              ${flex("center")}
            `
          )}
        >
          <CarrierLogo
            carrierID={props.benefit?.ProviderID}
            planCarrierName={props.benefit?.ProviderName}
            maxHeight="80px"
            position="center"
          />
        </div>
      ) : null;
    },
    [props.benefit?.ProviderID]
  );

  return (
    <motion.div
      key={"fsaplanvie_question"}
      initial="pageInitial"
      animate="pageAnimate"
      exit="pageExit"
      variants={{
        pageInitial: {
          x: 400,
          opacity: 0,
        },
        pageAnimate: {
          x: 0,
          opacity: 1,
        },
        pageExit: {
          x: -400,
          opacity: 0,
        },
      }}
      className={benefitContainer}
      style={{
        border: `4px solid ${
          props.designStyles?.Theme?.Body?.ButtonColor
            ? props.designStyles?.Theme?.Body?.ButtonColor
            : "#25282D"
        }`,
      }}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          ${smallScreen} {
            ${flex("center column")}
            .large-screen {
              display: none;
            }
          }
          ${largeScreen} {
            .small-screen {
              display: none;
            }
          }
        `}
      >
        <div
          className={css`
            max-width: 66%;

            margin-right: 16px;
            @media only screen and (max-width: 600px) {
              min-width: 140px;
            }
          `}
        >
          <div>
            <Text
              h2
              css={`
                font-size: 20px;
              `}
            >
              Flexible Spending Account
            </Text>
            <Logo className="small-screen" />
            <Text>
              Pay for certain out-of-pocket healthcare costs, while lowering
              your taxable income.
            </Text>
          </div>
        </div>
        <Logo className="large-screen" />
      </div>

      <div
        className={css`
          display: flex;
          align-items: center;
          @media only screen and (min-width: 600px) {
            display: none;
          }
        `}
      >
        <div
          className={css`
            font-size: 12px;
            margin: 0;
            padding: 0;
            flex-grow: 1;
            margin-right: 40px;
            text-align: left;
          `}
        >
          Pay for certain out-of-pocket healthcare costs, while lowering your
          taxable income.
        </div>
      </div>

      <Modal display={learnMoreOpen} onClose={() => setLearnMoreOpen(false)}>
        <div
          className={css`
            padding: 24px;
          `}
        >
          <div
            className={css`
              ${flex("space-between")}
            `}
          >
            <Text h2>Flexible Spending Account</Text>
            <Button styles="icon">
              <X />
            </Button>
          </div>
          <div
            className={css`
              max-height: 50vh;
              overflow-y: scroll;
              ${scrollbar.hide}
            `}
          >
            <Typography>
              A Flexible Spending Account (FSA) is a special account you put
              money into (pre-tax) that you use to pay for certain out-of-pocket
              healthcare costs, while lowering your taxable income. You can
              choose your amount (up to the annual limit), and that full amount
              is available to you immediately. That amount will be deducted
              through payroll deductions pre-tax.
            </Typography>

            <div className={listContainer}>
              <div className={itemContainer}>
                <Typography>2024 IRS Annual FSA Maximum</Typography>
                <Typography style={{ fontWeight: "bold" }}>$3,200</Typography>
              </div>
            </div>

            <Typography style={{ marginTop: "16px", fontWeight: "bold" }}>
              What can you use your FSA funds for?
            </Typography>

            <div className={cardContainer}>
              <CurrencyCircleDollar />
              <Typography>Copays or deductibles</Typography>
            </div>

            <div className={cardContainer}>
              <FirstAid />
              <Typography>Qualified medical expenses</Typography>
            </div>

            <div className={cardContainer}>
              <Bandaids />
              <Typography>Certain medical equipment</Typography>
            </div>

            <div className={cardContainer}>
              <FaceMask />
              <Typography>Dental and vision expenses</Typography>
            </div>

            <div className={cardContainer}>
              <Eye />
              <Typography>LASIK surgery</Typography>
            </div>

            <div className={cardContainer}>
              <UsersThree />
              <Typography>Therapy and Counseling</Typography>
            </div>

            <div className={cardContainer}>
              <PlusCircle />
              <Typography>And much more!</Typography>
            </div>

            <Typography style={{ marginTop: "16px", fontWeight: "bold" }}>
              Who owns your FSA account?
            </Typography>
            <Typography>
              Your employer owns your account. If you do not use your funds by
              the end of the year you will lose them.
            </Typography>

            <Typography style={{ marginTop: "16px", fontWeight: "bold" }}>
              Access to your funds
            </Typography>
            <Typography>
              Employees have access to the full annual election amount at any
              time.
            </Typography>

            {!!titleDescriptions.length && (
              <div className={listContainer}>
                {titleDescriptions.map((item) => (
                  <div
                    className={itemContainer}
                    key={item.Title + item.Description}
                  >
                    <Typography>{item.Title}</Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {item.Description}
                    </Typography>
                  </div>
                ))}
              </div>
            )}

            {props.benefit.Disclaimer && (
              <div
                className={css`
                  font-size: 9px;
                  font-weight: 400;
                  margin: 16px;
                `}
              >
                {parseDisclaimer(props.benefit.Disclaimer)}
              </div>
            )}
          </div>
        </div>
      </Modal>

      <div
        className={css`
          display: flex;
          justify-content: left;
        `}
      >
        <Button
          naked
          css={`
            text-decoration: underline;
            text-transform: none;
            padding: 8px 0;
          `}
          aria-label="Learn more"
          onClick={() => setLearnMoreOpen(!learnMoreOpen)}
          data-ph-capture-attribute-source={
            posthogAttrs?.types?.RECOMMENDATIONS
          }
          data-ph-capture-attribute-action={posthogAttrs?.actions?.VIEW_MORE}
          data-ph-capture-attribute-product-type={props.benefit?.Type}
        >
          Learn More
        </Button>
      </div>
    </motion.div>
  );
};
