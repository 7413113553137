import { css } from "emotion";

export const extraSmallScreen = "@media only screen and (max-width: 380px)";
export const smallScreen = "@media only screen and (max-width: 600px)";
export const largeScreen = "@media only screen and (min-width: 600px)";

export const container = css`
  padding-top: 8px;
  border-top: 1px solid #d1dae3;
`;

export const listContainer = css`
  margin: 24px 0;
  border-bottom: 1px solid #d1dae3;
  > div:nth-child(odd) {
    background-color: #f5f7fa;
  }
`;

export const itemContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  white-space: normal;
  > p {
    flex-grow: 1;
    text-align: right;
    :first-child {
      text-align: left;
    }
    :last-child {
      margin-left: 16px;
    }
  }
  ${extraSmallScreen} {
    flex-direction: column;
    p {
      text-align: center !important;
    }
  }
`;

export const itemWithLabel = css`
  flex-grow: 1;
  text-align: right;
  margin-left: 16px;
`;

export const titleStyle = css`
  font-size: 20px;
  font-weight: bold;
  margin: 16px 0;
  margin-top: 0;
  flex-grow: 1;
  text-align: left;

  ${smallScreen} {
    font-size: 16px;
    margin: 8px 0;
    padding: 0;
  }
`;

export const benefitListContainer = css`
  margin-top: 32px;
  border-radius: 16px;
  padding: 16px 24px;

  .title {
    ${titleStyle}
  }
  .section-title {
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 16px;
    ${smallScreen} {
      text-align: center;
      margin: 0 16px;
      font-size: 18px;
    }
  }
  .view-button {
    margin-top: 8px;
  }
  ${smallScreen} {
    text-align: center;
    padding: 8px 0;
  }
`;

export const borderStyles = css``;

export const benefitContainer = css`
  border-radius: 16px;

  background: #fff;
  padding: 32px;
  margin-bottom: 16px;
  ${smallScreen} {
    padding: 12px 12px;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 0;
    margin-bottom: 16px;
  }
`;

export const logoContainer = css`
  display: flex;
  justify-content: end;
  align-items: start;
  margin-bottom: -24px;
  flex-grow: 1;
  width: 100px;
  height: 80px;
  img {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    object-position: right bottom;
  }
  ${smallScreen} {
    flex-grow: 1;
    justify-content: center;
    width: 100%;
    padding: 8px 0;
    margin-bottom: 0px;
  }
`;

export const questionContainer = css`
  display: flex;
  align-items: start;
  ${smallScreen} {
    display: none;
  }
  .question {
    margin: 0;
    padding: 0;
    flex-grow: 1;
    text-align: left;
    white-space: normal;
    overflow-wrap: break-word;
  }
`;
