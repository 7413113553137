import React, { Component } from "react";
import { Text, TextInput } from "grommet";
import { motion } from "framer-motion";

import BriteTextInput from "./inputs/BriteTextInput";

import { css } from "emotion";

export class DecisionToolDoctorMedsQuestion extends Component {
  render() {
    return (
      <motion.div
        key={`meds_question${this.props.type}`}
        initial="pageInitial"
        animate="pageAnimate"
        exit="pageExit"
        variants={{
          pageInitial: {
            y: 400,
            opacity: 0,
          },
          pageAnimate: {
            y: 0,
            opacity: 1,
          },
          pageExit: {
            y: -400,
            opacity: 0,
          },
        }}
        className={css`
          background: #ffffff;

          box-shadow: 0px 2px 8px rgba(37, 40, 45, 0.25);
          border-radius: 8px;
          padding: 24px;
          margin-bottom: 32px;

          text-align: left;
        `}
      >
        <h1
          className={css`
            color: #25282d;
            font-size: 23px;
            line-height: 32px;
            margin-block-start: 0px;
            margin-block-end: 12px;
          `}
        >
          {" "}
          {this.props.type === "personal"
            ? "How many prescriptions do you take every month?"
            : "How many prescriptions do your dependents take every month?"}{" "}
        </h1>

        {this.props.shouldDisplayDependentQuestions && (
          <div
            className={css`
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              /* identical to box height, or 133% */

              letter-spacing: 0.15px;

              color: #25282d;
              margin-top: 16px;
            `}
          >
            For you
          </div>
        )}

        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 4px;
            flex-wrap: wrap;
          `}
        >
          <div
            className={css`
              margin-top: 16px;
            `}
          >
            <BriteTextInput
              value={this.props.numberGenericMeds}
              id="Generics-input"
              label="Generics"
              variant="outlined"
              type="number"
              placeholder="# of generics"
              inputProps={{ inputMode: "numeric" }}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  this.props.setNumberGenericMeds(e.target.value);
                }
              }}
            />
          </div>
          <div
            className={css`
              margin-top: 16px;
            `}
          >
            <BriteTextInput
              value={this.props.numberBrandMeds}
              id="brand-name-input"
              label="Brand Name"
              variant="outlined"
              type="number"
              placeholder="# of brand name"
              inputProps={{ inputMode: "numeric" }}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  this.props.setNumberBrandMeds(e.target.value);
                }
              }}
            />
          </div>
          <div
            className={css`
              margin-top: 16px;
            `}
          >
            <BriteTextInput
              placeholder="# of preferred drugs"
              value={this.props.numberPreferredMeds}
              id="preferred-input"
              label="Preferred"
              variant="outlined"
              type="number"
              inputProps={{ inputMode: "numeric" }}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  this.props.setNumberPreferredMeds(e.target.value);
                }
              }}
            />
          </div>
          <div
            className={css`
              margin-top: 16px;
            `}
          >
            <BriteTextInput
              placeholder="# of specialty"
              value={this.props.numberSpecialtyMeds}
              id="specialty-input"
              label="Specialty"
              variant="outlined"
              type="number"
              inputProps={{ inputMode: "numeric" }}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  this.props.setNumberSpecialtyMeds(e.target.value);
                }
              }}
            />
          </div>
        </div>

        {this.props.shouldDisplayDependentQuestions && (
          <>
            <div
              className={css`
                font-family: "Roboto";
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                /* identical to box height, or 133% */

                letter-spacing: 0.15px;

                color: #25282d;
                margin-top: 24px;
              `}
            >
              For your dependents
            </div>
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 4px;
                flex-wrap: wrap;
              `}
            >
              <div
                className={css`
                  margin-top: 16px;
                `}
              >
                <BriteTextInput
                  value={this.props.dependantNumberGenericMeds}
                  id="Generics-input"
                  label="Generics"
                  variant="outlined"
                  type="number"
                  placeholder="# of generics"
                  inputProps={{ inputMode: "numeric" }}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      this.props.setDependantNumberGenericMeds(e.target.value);
                    }
                  }}
                />
              </div>
              <div
                className={css`
                  margin-top: 16px;
                `}
              >
                <BriteTextInput
                  value={this.props.dependantNumberBrandMeds}
                  id="brand-name-input"
                  label="Brand Name"
                  variant="outlined"
                  type="number"
                  placeholder="# of brand name"
                  inputProps={{ inputMode: "numeric" }}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      this.props.setDependantNumberBrandMeds(e.target.value);
                    }
                  }}
                />
              </div>
              <div
                className={css`
                  margin-top: 16px;
                `}
              >
                <BriteTextInput
                  placeholder="# of preferred drugs"
                  value={this.props.dependantNumberPreferredMeds}
                  id="preferred-input"
                  label="Preferred"
                  variant="outlined"
                  type="number"
                  inputProps={{ inputMode: "numeric" }}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      this.props.setDependantNumberPreferredMeds(
                        e.target.value
                      );
                    }
                  }}
                />
              </div>
              <div
                className={css`
                  margin-top: 16px;
                `}
              >
                <BriteTextInput
                  placeholder="# of specialty"
                  value={this.props.dependantNumberSpecialtyMeds}
                  id="specialty-input"
                  label="Specialty"
                  variant="outlined"
                  type="number"
                  inputProps={{ inputMode: "numeric" }}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      this.props.setDependantNumberSpecialtyMeds(
                        e.target.value
                      );
                    }
                  }}
                />
              </div>
            </div>
          </>
        )}
        <div
          className={css`
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            /* identical to box height, or 200% */

            letter-spacing: 0.15px;

            color: #9aa7b5;
            margin-top: 16px;
          `}
        >
          *Your answer to this question will not affect your rate
        </div>
      </motion.div>
    );
  }
}
